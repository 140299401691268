import React from "react";
import { VOICE_ASSISTANT } from "../../../Constants/textContent";
import styles from "./voice_assistant_list-style.module.css";

export default function VoiceAssistantList() {
  return (
    <ul className={styles.listContainer}>
      {VOICE_ASSISTANT?.list?.map((item) => {
        return (
          <li className={styles.list} key={item}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}
