import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { APPAREL_TRY_ON_STATIC_FILE } from '../../../Constants/apiEndpoint';
import { SOMETHING_WENT_WRONG } from '../../../Constants/errorMessage';

import { getModelListService } from '../../../Services/cosmeticTryOnService';
import { virtualTryOnContext } from '../../../Context/VirtualTryOn/VirtualTryOnProvider';
import { selectModel } from '../../../Context/VirtualTryOn/virtualTryOnActions';
import Loader from '../../Loader/Loader';
import commonStyles from '../../../Assets/styles/common-style.module.css';
import styles from './models_slider-style.module.css';

const ModelsSlider = (props) => {
	const { setShowModels } = props;

	const [state, dispatch] = useContext(virtualTryOnContext);

	const [modelList, setModelList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchModelList = async () => {
		setIsLoading(true);
		const response = await getModelListService();
		if (response?.data) {
			const newLinks = response?.data?.data?.links?.map((item, idx) => {
				return {
					id: idx,
					fileName: item?.name,
					imgLink: `${APPAREL_TRY_ON_STATIC_FILE}${item?.path}`,
				};
			});
			setModelList(newLinks);
		} else {
			toast.error(SOMETHING_WENT_WRONG);
			setShowModels(false);
		}
		setIsLoading(false);
	};

	const modelClickHandler = (data) => {
		setShowModels(false);
		dispatch(selectModel(data?.fileName, data?.imgLink));
	};

	useEffect(() => {
		fetchModelList();
	}, []);

	return (
		<div className={`${styles.container} ${commonStyles.customScrollBar}`}>
			{isLoading && (
				<div className={styles.loaderWrapper}>
					<Loader type='secondary' />
				</div>
			)}
			{!isLoading &&
				modelList?.map((item) => {
					return (
						<div
							onClick={() => modelClickHandler(item)}
							className={`${styles.imgWrapper} ${
								item?.fileName === state?.image?.id ? styles.activeImg : ''
							}`}
							key={item.id}
						>
							<img
								className={styles.img}
								src={item?.imgLink}
								alt=''
							/>
						</div>
					);
				})}
		</div>
	);
};

export default ModelsSlider;
