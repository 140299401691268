// video transcription service
export const VIDEO_STATIC_FILE = `${process.env.REACT_APP_VIDEO_TRANSCRIPTION_BASE_URL}`;
export const FILE_UPLOAD_API = `${process.env.REACT_APP_VIDEO_TRANSCRIPTION_BASE_URL}upload_file`;
export const TRANSCRIBE_VIDEO_API = `${process.env.REACT_APP_VIDEO_TRANSCRIPTION_BASE_URL}transcribe_video`;
export const GET_FILE_API = `${process.env.REACT_APP_VIDEO_TRANSCRIPTION_BASE_URL}get_saved_file`;

// virtual try on
export const STATIC_FILE = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}`;
export const COSMETICS_IMAGE_UPLOAD_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}upload_image`;
export const COSMETICS_STATUS_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}status`;
export const LIVE_TRY_ON_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}livetryon`;
export const EARING_LIST_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}ear_ringslist`;
export const EARING_TRY_ON_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}ear_ringtryon`;
export const RING_LIST_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}ringslist`;
export const RING_TRY_ON_API = `${process.env.REACT_APP_COSMETIC_TRY_ON_BASE_URL}ringtryon`;

// voice assistant
export const VOICE_ASSISTANT_API = `${process.env.REACT_APP_VOICE_ASSIST_BASE_URL}api/v1/tell/tell`;

// apparel-try-on
export const APPAREL_TRY_ON_STATIC_FILE = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}`;
export const APPAREL_MODEL_IMAGE_UPLOAD_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}upload_model_image`;
export const APPAREL_MODEL_IMAGE_STATUS_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}model_status`;
export const APPAREL_CLOTHES_IMAGE_UPLOAD_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}upload_cloth_image`;
export const APPAREL_CLOTHES_IMAGE_STATUS_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}cloth_status`;
export const APPAREL_TRY_ON_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}tryon_function`;
export const APPAREL_STATUS_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}status`;
export const APPAREL_MODEL_LIST_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}models_list`;
export const APPAREL_CLOTHES_LIST_API = `${process.env.REACT_APP_APPAREL_TRY_ON_BASE_URL}cloth_list`;

//recommendation-engine
export const RECOMMENDATION_ENGINE_PRODUCTS_LIST_API = `${process.env.REACT_APP_RECOMMENDATION_ENGINE_BASE_URL}product-list/`;
export const RECOMMENDATION_ENGINE_PRODUCT_DESC_API = `${process.env.REACT_APP_RECOMMENDATION_ENGINE_BASE_URL}product-description`;
export const RECOMMENDATION_ENGINE_ITEM_LIST_API = `${process.env.REACT_APP_RECOMMENDATION_ENGINE_BASE_URL}get-recommendations`;
