import ExternalRedirect from "../../ExternalRedirect/ExternalRedirect";
import {
  VIRTUAL_MAKEUP,
  VIDEO_TRANSCRIBE,
  VOICE_ASSISTANT,
  RECOMMENDATION_ENGINE,
} from "../../../Routes/Routes";
import { VIRTUAL_TRY_ON_APP_LINK } from "../../../Constants/externalLinks";
import "./ai-prototype-section.css";

const AIPrototypeSection = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 aiSolutions">
            <h3> AI Solutions</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div className="frame-126">
              <div className="virtualHeading">
                <h4>Virtual Try On</h4>
                <ExternalRedirect
                  to={VIRTUAL_TRY_ON_APP_LINK}
                  openLocation="newTab"
                >
                  <div className="downloadMobileApp">Download Mobile App</div>
                </ExternalRedirect>
              </div>
              <p>
                {" "}
                Elevate your customer's shopping experience with our virtual
                try-on for makeup & jewelry. Our hyper-realistic try-on services
                provide a modern & personalized experience at point-of-sale that
                enables customers to visualize their final looks before
                committing to buying a product. Our virtual try-on solutions are
                scalable and can be adapted to other products such as clothes,
                contact lenses and some other makeup products as per clients'
                requirements.
              </p>
              <ExternalRedirect to={VIRTUAL_MAKEUP} openLocation="newTab">
                <div className="tryDemoNow">Try Demo Now</div>
              </ExternalRedirect>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
            <div className="frame-126">
              <h4>Video Transcription</h4>
              <p className="content">
                {" "}
                Automatically transcribe video to text with our video
                transcription solutions. Powered by the latest AI technology,
                our solution can achieve more than 80% accuracy, depending on
                the audio quality and the language being spoken. The same
                capabilities can also be extended to speech-to-text
                transcription as per the client's business needs.
              </p>
              <ExternalRedirect to={VIDEO_TRANSCRIBE} openLocation="newTab">
                <div className="tryDemoNow">Try Demo Now</div>
              </ExternalRedirect>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 ">
            <div className="frame-126">
              <h4>Voice Assistant</h4>
              <p className="content">
                {" "}
                Facilitate an easy & seamless appointment booking experience for
                your customers with our conversational AI solutions. Built using
                advanced NLP and ML, our voice assistant is intuitive and easy
                to use. The following demo is a mere sample for your reference,
                the final solution is designed based on client requirements and
                challenges.
              </p>
              <ExternalRedirect to={VOICE_ASSISTANT} openLocation="newTab">
                <div className="tryDemoNow">Try Demo Now</div>
              </ExternalRedirect>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 ">
            <div className="frame-126">
              <h4>Recommendation Engine</h4>
              <p>
                {" "}
                Shopping has never been more fun and effortless. With a smart
                recommendation engine, our solution analyzes your unique
                shopping habits to generate personalized product suggestions
                that match your needs and preferences. The more you use our
                solution, the smarter it becomes, allowing you to discover new
                items that fit your style.
              </p>
              <ExternalRedirect
                to={RECOMMENDATION_ENGINE}
                openLocation="newTab"
              >
                <div className="tryDemoNow">Try Demo Now</div>
              </ExternalRedirect>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIPrototypeSection;
