import VirtualTryOnProvider from './VirtualTryOn/VirtualTryOnProvider';

// Import other created Providers and add them here -
const providers = [VirtualTryOnProvider];

const combineProvider = (components) => {
	return components.reduce(
		(AccumulatedComponents, CurrentComponent) => {
			return ({ children }) => {
				return (
					<AccumulatedComponents>
						<CurrentComponent>{children}</CurrentComponent>
					</AccumulatedComponents>
				);
			};
		},
		({ children }) => <>{children}</>
	);
};

// Combining multiple providers to single provider - this will be wrapped around App.js
export const GlobalProvider = combineProvider(providers);
