import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Footer from '../Footer/Footer';
// import Header from '../Header/Header';
import BlogsSection from '../UnthinkableHomePage/BlogsSection/BlogsSection';
import GetInTouch from '../UnthinkableHomePage/GetInTouch/GetInTouch';
import Navbar from '../UnthinkableHomePage/Navbar/Navbar';
import Footer from '../UnthinkableHomePage/Footer/Footer';

const Layout = (props) => {
	const { children } = props;
	return (
		<div>
			<ToastContainer theme='colored' />
			{/* <Header /> */}
			<Navbar />
			<div>{children}</div>
			<BlogsSection />
			<GetInTouch />
			<Footer />
			{/* <Footer /> */}
		</div>
	);
};

export default Layout;
