import IMG from "../../../Templates/Unthinkable/images/flag.webp";
import ExternalRedirect from "../../ExternalRedirect/ExternalRedirect";

import "./main-banner-style.css";

const MainBanner = () => {
  var words = [
      "AI Data-Driven Solutions",
      "Data-Driven Solutions",
      "Intelligent Engagement",
    ],
    part,
    i = 0,
    offset = 0,
    len = words.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 70;
  var wordflick = function () {
    setInterval(function () {
      if (forwards) {
        if (offset >= words[i].length) {
          ++skip_count;
          if (skip_count === skip_delay) {
            forwards = false;
            skip_count = 0;
          }
        }
      } else {
        if (offset === 0) {
          forwards = true;
          i++;
          offset = 0;
          if (i >= len) {
            i = 0;
          }
        }
      }
      part = words[i].substr(0, offset);
      let img = `<img src=${IMG} alt='' className='flag'/>`;
      if (skip_count === 0) {
        if (forwards) {
          offset++;
        } else {
          offset--;
        }
      }
      document.getElementById("word").innerHTML = part + img;
    }, speed);
  };

  window.addEventListener(
    "load",
    function () {
      // do something here ...
      wordflick();
    },
    false
  );

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="intelligence">#Intelligence</div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 banner-heading-orange">
              <h1 id="word">
                {" "}
                Increase accuracy
                <img
                  src={IMG}
                  alt=""
                  // className='flag'
                />
              </h1>
            </div>
            {/* <div className=' col-lg-4 col-md-12 col-sm-12 flag'></div> */}
          </div>
          <div className="row">
            <div className="col-12 banner-heading-black">
              <h1>of your digital platform</h1>
            </div>
          </div>
          <div className="row">
            <ExternalRedirect
              to="https://www.unthinkable.co/intelligence/"
              openLocation="newTab"
            >
              <div className="col-12 getInTouch">Get in touch</div>
            </ExternalRedirect>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;
