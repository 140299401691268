import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getItem, setItem } from '../Services/sessionStorageService';

let uuid = '';
if (getItem('uuid')) {
	uuid = getItem('uuid');
} else {
	uuid = uuidv4();
	setItem('uuid', uuid);
}

const axiosInstance = axios.create();
const axiosFileUploadInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
	config.headers['Content-Type'] = 'application/json';
	config.headers['uuid'] = uuid;
	return config;
});

axiosFileUploadInstance.interceptors.request.use((config) => {
	config.headers['Content-Type'] = 'multipart/form-data';
	return config;
});

export { axiosInstance, axiosFileUploadInstance };
