import { axiosFileUploadInstance, axiosInstance } from '../API/axiosInstance';
import {
	FILE_UPLOAD_API,
	GET_FILE_API,
	TRANSCRIBE_VIDEO_API,
} from '../Constants/apiEndpoint';

export const uploadTranscribeVideoService = async (fileName, videoFilePath) => {
	const response = { error: '', data: null };
	const data = { file_name: fileName, file_path: videoFilePath };
	try {
		response.data = await axiosInstance.post(TRANSCRIBE_VIDEO_API, data);
	} catch (err) {
		if (err?.code === 'ERR_NETWORK') {
			response.error = err?.message;
		} else {
			response.error = err?.response;
		}
	}
	return response;
};

export const uploadFileService = async (file) => {
	const response = { error: '', data: null };
	const formData = new FormData();
	formData.append('file', file, file?.name);
	try {
		response.data = await axiosFileUploadInstance.post(
			FILE_UPLOAD_API,
			formData
		);
	} catch (err) {
		if (err?.code === 'ERR_NETWORK') {
			response.error = err?.message;
		} else {
			response.error = err?.response;
		}
	}
	return response;
};

export const getDummyVideoService = async () => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(GET_FILE_API);
	} catch (err) {
		if (err?.code === 'ERR_NETWORK') {
			response.error = err?.message;
		} else {
			response.error = err?.response;
		}
	}
	return response;
};
