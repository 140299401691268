export const CHIMNEY_SWEEP = "#263238";
export const DARK = "#1D2331";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const PATERNOSTER = "#C7C7C7";
export const SAMBUCUS = "#151B29";
export const SILVER_SPOON = "#D2D2D2";
export const BLACK_TIE = "#474747";
export const SPARKLING_COSMO = "#ff6f6f";
export const CAPE_HOPE = "#D7D7D7";
export const SONIC_SILVER = "#757575";
export const FELWOOD_LEAVES = "#2BC91D";
