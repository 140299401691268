import {
  DARK,
  SAMBUCUS,
  SILVER_SPOON,
  WHITE,
} from "../../../Constants/colors.js";

export const styles = {
  gridContainer: {
    backgroundColor: DARK,
  },
  reLogoContainer: {
    color: WHITE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  aboutUsContainer: {
    color: WHITE,
    paddingLeft: "229px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width:768px) and (max-width:992px)": {
      marginTop: "20px",
      paddingLeft: "0px",
    },
    "@media (max-width:767px)": {
      marginTop: "20px",
      paddingLeft: "0px",
    },
  },
  footerContainer: {
    padding: "135px 192px 134px 192px",
    backgroundColor: DARK,
    marginTop: "100px",
    "@media (max-width:767px)": {
      padding: "30px",
    },
  },
  contactUsContainer: {
    background: SAMBUCUS,
    color: WHITE,
    textAlign: "center",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
  },
  emailImage: {
    marginRight: "13px",
  },
  aboutText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: "14px",
  },
  aboutData: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: SILVER_SPOON,
    "@media (min-width:768px) and (max-width:992px)": {
      width: "100%",
    },
    "@media (max-width:767px)": {
      width: "100%",
      fontSize: "14px",
      lineHeight: "21px",
    },
  },
  reLogo: {
    width: "200px",
    height: "200px",
    "@media(max-width:767px)": {
      width: "150px",
      height: "150px",
    },
  },
  reText: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  logoImg: {
    width: "200px",
    height: "200px",
    "@media(max-width:768px)": {
      width: "150px",
      height: "150px",
    },
  },
};
