import { useContext } from 'react';

import CANCEL_ICON from '../../../../Assets/img/Cancel Icon-2.svg';
import ADD_ICON from '../../../../Assets/img/add_icon.svg';
import { APPAREL_TRY_ON_STATIC_FILE } from '../../../../Constants/apiEndpoint';
import { SOMETHING_WENT_WRONG_TRY_FIX } from '../../../../Constants/errorMessage';

import {
	processedImageError,
	toggleLoader,
	processedImageStatus,
	processedImage,
	selectProduct,
	removeClothImage,
} from '../../../../Context/VirtualTryOn/virtualTryOnActions';
import { virtualTryOnContext } from '../../../../Context/VirtualTryOn/VirtualTryOnProvider';
import {
	apparelStatusService,
	apparelTryOnService,
} from '../../../../Services/cosmeticTryOnService';
import styles from './product_upload-style.module.css';
import { callApiUntilSuccessOrError } from '../../../../Utils/helperFunctions';

const ProductUpload = (props) => {
	const { label, iconText, id, imgFile, uploadModal, imgLabel, subLabel } = props;

	const [state, dispatch] = useContext(virtualTryOnContext);

	const selectProductHandler = () => {
		if (state?.selectedProduct?.productId !== state?.clothImage?.id) {
			dispatch(selectProduct(state?.clothImage?.id, state?.clothImage?.id));
			handleTryOn();
		}
	};

	const handleTryOn = async () => {
		dispatch(toggleLoader(true));
		const response = await apparelTryOnService({
			person: state?.image?.id,
			cloth: state?.clothImage?.id,
		});
		if (response?.data) {
			callApiUntilSuccessOrError(
				TryOnStatus,
				TryOnCompletedFunc,
				TryOnSuccessFunc,
				TryOnErrorFunc,
				{ task_id: response?.data?.data?.task_id },
				50
			);
		} else {
			dispatch(processedImageError());
		}
	};

	const TryOnStatus = async ({ task_id }) => {
		const response = await apparelStatusService(task_id);
		if (response?.data?.data?.percentage === '100') {
			return { status: 'completed', filePath: response?.data?.data?.file_path };
		} else if (
			response?.data?.data?.status === 'error' ||
			response?.error?.status === 500
		) {
			return {
				status: 'error',
				msg: SOMETHING_WENT_WRONG_TRY_FIX,
			};
		} else if (
			response?.error?.status === 400 ||
			response?.error?.status === 404
		) {
			return { status: 'error', msg: response?.error?.data?.Error };
		} else {
			return {
				status: 'success',
				percentage: response?.data?.data?.percentage,
			};
		}
	};

	const TryOnCompletedFunc = ({ filePath }) => {
		dispatch(processedImage(`${APPAREL_TRY_ON_STATIC_FILE}${filePath}`));
	};

	const TryOnSuccessFunc = ({ percentage }) => {
		dispatch(processedImageStatus(percentage));
	};

	const TryOnErrorFunc = ({ msg }) => {
		dispatch(processedImageError(msg));
	};

	const cancelBtnHandler = (e) => {
		e.stopPropagation();
		dispatch(removeClothImage());
	};

	return (
		<>
			<div
				onClick={imgFile ? selectProductHandler : uploadModal}
				className={styles.container}
			>
				{!imgFile ? (
					<div className={styles.wrapper}>
						<img
							className={styles.icon}
							src={ADD_ICON}
							alt=''
						/>
						<p className={styles.iconText}>{iconText}</p>
					</div>
				) : (
					<>
						<img
							className={styles.cancelIcon}
							src={CANCEL_ICON}
							alt='/'
							onClick={(e) => cancelBtnHandler(e)}
						/>
						<img
							className={`${styles.productImage} ${
								state?.selectedProduct?.productId === id
									? styles.activeProductImage
									: ''
							}`}
							src={imgFile}
							alt=''
						/>
					</>
				)}
				<p className={styles.label}>{imgFile ? imgLabel : label}</p>
				<p className={styles.subLabel}>{imgFile ? imgLabel : subLabel}</p>

			</div>
		</>
	);
};

export default ProductUpload;
