import ExternalRedirect from '../../ExternalRedirect/ExternalRedirect';
import './get-in-touch-styles.css';

const GetInTouch = () => {
	return (
		<section className='black'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 col-md-8 col-sm-12 '>
						<h1>
							Need help with <span className='text-style'>intelligence</span> of
							your digital platform?
						</h1>
						<p>
							Get to know how our AI experts can help you transform your digital
							platforms.
							<br />
							Schedule a call with our Intelligence expert
						</p>
					</div>
				</div>
				<ExternalRedirect
					to='https://www.unthinkable.co/intelligence/'
					openLocation='newTab'
				>
					<div className='row'>
						<div className='col-12 orange-btn'>Get in touch</div>
					</div>
				</ExternalRedirect>
			</div>
		</section>
	);
};

export default GetInTouch;
