import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import ExternalRedirect from "../../ExternalRedirect/ExternalRedirect";
import IMG from "../../../Templates/Unthinkable/images/blogs.webp";
import "./blogs-section-style.css";

const BlogsSection = () => {
  const sliderRef = useRef();

  return (
    <section className="blogs">
      <div className="container">
        <div className="row blog-parent-row">
          <div className="blog-main">
            <img src={IMG} alt="" />
          </div>
          <div className=" blog-row">
            <Slider
              ref={sliderRef}
              slidesToShow={2}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={2000}
              infinite={true}
              dots={false}
              draggable={true}
              responsive={[
                {
                  breakpoint: 1150,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              <div className=" blog-row">
                <div className="blog">
                  <div>
                    <span className="blog-intelligence">Intelligence</span>
                    <p>
                      {" "}
                      All you need to know about Question-Answering (QA) systems
                      built using NLP
                    </p>
                    <span className="QA-utility">
                      {" "}
                      Explore various solutions for building a QA Utility
                    </span>
                  </div>
                  <ExternalRedirect
                    to="https://www.unthinkable.co/blog/all-you-need-to-know-about-question-answering-qa-systems-built-using-nlp/"
                    openLocation="newTab"
                  >
                    <div className="readmore">Read</div>
                  </ExternalRedirect>
                </div>
              </div>
              <div className=" blog-row">
                <div className="blog">
                  <div>
                    <span className="blog-intelligence">Intelligence</span>
                    <p>
                      {" "}
                      Business Intelligence: The Future of Data-Driven Decision
                      Making
                    </p>
                    <span className="QA-utility">
                      {" "}
                      Explore how BI tools are transforming decision-making.
                    </span>
                  </div>
                  <ExternalRedirect
                    to="https://www.unthinkable.co/blog/business-intelligence-the-future-of-data-driven-decision-making/"
                    openLocation="newTab"
                  >
                    <div className="readmore">Read</div>
                  </ExternalRedirect>
                </div>
              </div>
              <div className=" blog-row">
                <div className="blog">
                  <div>
                    <span className="blog-intelligence">Intelligence</span>
                    <p> The Need of Business Intelligence in Marketing</p>
                    <span className="QA-utility">
                      {" "}
                      Explore how business intelligence is transforming
                      marketing.
                    </span>
                  </div>
                  <ExternalRedirect
                    to="https://www.unthinkable.co/blog/the-need-of-business-intelligence-in-marketing/"
                    openLocation="newTab"
                  >
                    <div className="readmore">Read</div>
                  </ExternalRedirect>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogsSection;
