import React, { useContext } from "react";
import { selectCategory } from "../../../Context/VirtualTryOn/virtualTryOnActions";
import { virtualTryOnContext } from "../../../Context/VirtualTryOn/VirtualTryOnProvider";
import styles from "./virtual_try_on_tab_container.module.css";

const tabList = [
  { id: 1, label: "Makeup", name: "makeup", subCategory: "lipstick" },
  { id: 2, label: "Jewellery", name: "jewelry", subCategory: "rings" },
  { id: 3, label: "Apparel", name: "apparel", subCategory: "clothes" },
];

export default function VirtualTryOnTabContainer() {
  const [state, dispatch] = useContext(virtualTryOnContext);
  const tabSwitchHandler = (category, subCategory) => {
    if (state?.selectedProduct?.category !== category) {
      dispatch(selectCategory(category, subCategory));
    }
  };
  return (
    <div className={styles.container}>
      {tabList?.map((item) => {
        return (
          <div
            key={item?.id}
            className={`${styles.tab}  ${
              state?.selectedProduct?.category === item?.name
                ? styles.activeTab
                : ""
            } ${item?.name === "jewelry" && styles.jewelryTab}`}
            onClick={() => tabSwitchHandler(item?.name, item?.subCategory)}
          >
            {item?.label}
          </div>
        );
      })}
    </div>
  );
}
