export const VIDEO_FILE_SIZE = 20000000;
export const IMAGE_FILE_SIZE = 1000000;
export const TAB_WIDTH = 992;
export const MOBILE_WIDTH = 767;
export const sizes = ["S", "M", "L", "XL"];
export const RECOMMENDATION_BASIS = {
  ALL_FEATURES: "all_features",
  CATEGORY_FEATURES: "category_features",
  COLOR_FEATURES: "colour_features",
  PATTERN_FEATURES: "pattern_features",
};
