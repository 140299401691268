import { BLACK, CHIMNEY_SWEEP } from "../../../Constants/colors.js";

export const styles = {
  userContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 15px 0px 35px",
    "@media(max-width:767px)": {
      marginLeft: "15px",
      marginRight: "5px",
    },
  },
  usernameText: {
    marginLeft: "10px",
  },
  appBar: {
    backgroundColor: CHIMNEY_SWEEP,
    height: "85px",
    justifyContent: "center",
    "@media(max-width:767px)": {
      height: "70px",
    },
  },
  navbarBox: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zindex: 2,
  },
  title: {
    flexGrow: 1,
  },
  searchIcon: {
    color: BLACK,
  },
  logoImg: {
    width: "80px",
    height: "80px",
    "@media(max-width:768px)": {
      width: "70px",
      height: "70px",
    },
  },
};
