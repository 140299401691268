import { useRef } from 'react';
import { toast } from 'react-toastify';

// import uploadIcon from '../../Assets/img/Upload_Icon.svg';
import { IMAGE_FILE_SIZE } from '../../Constants/constants';
import { IMAGE_FILE_TOO_LARGE } from '../../Constants/errorMessage';
import uploadIcon from '../../Assets/img/Upload.svg';
import './UploadPhoto.css';

export default function UploadPhoto({ handleFile, customStyle }) {
	const hiddenFileInput = useRef(null);

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		const fileUploaded = event.target.files[0];
		if (fileUploaded.size > IMAGE_FILE_SIZE) {
			toast.error(IMAGE_FILE_TOO_LARGE);
		} else {
			handleFile(fileUploaded);
		}
	};

	return (
		<>
			<button
				className='upload-btn'
				onClick={handleClick}
			>
				<img
					src={uploadIcon}
					alt=''
				/>
				Upload Picture
			</button>

			<input
				type='file'
				ref={hiddenFileInput}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
		</>
	);
}
