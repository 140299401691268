import Backdrop from '../Backdrop/Backdrop';
import styles from './loader-style.module.css';

const Loader = (props) => {
	const { loaderText, type = 'primary' } = props;
	return (
		<div className={styles.container}>
			{type === 'primary' && (
				<>
					<Backdrop customStyles={styles.overlay} />
					<div className={styles.loader}>
						<div className={styles.wrapper}>
							<div className={styles.loaderInnerWrapper}>
								<div className={styles.loaderRing}></div>
								<div className={styles.loaderRing}></div>
								<div className={styles.loaderRing}></div>
								<div className={styles.textContent}>{loaderText}</div>
							</div>
						</div>
					</div>
				</>
			)}
			{type === 'secondary' && (
				<div className={styles.secondaryLoader}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			)}
		</div>
	);
};

export default Loader;
