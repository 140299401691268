import { axiosInstance, axiosFileUploadInstance } from '../API/axiosInstance';
import {
	APPAREL_CLOTHES_LIST_API,
	APPAREL_MODEL_LIST_API,
	COSMETICS_IMAGE_UPLOAD_API,
	COSMETICS_STATUS_API,
	EARING_LIST_API,
	EARING_TRY_ON_API,
	LIVE_TRY_ON_API,
	RING_LIST_API,
	RING_TRY_ON_API,
	APPAREL_MODEL_IMAGE_UPLOAD_API,
	APPAREL_CLOTHES_IMAGE_UPLOAD_API,
	APPAREL_TRY_ON_API,
	APPAREL_MODEL_IMAGE_STATUS_API,
	APPAREL_CLOTHES_IMAGE_STATUS_API,
	APPAREL_STATUS_API,
} from '../Constants/apiEndpoint';

export const uploadPhotoFileService = async (file) => {
	const response = { error: '', data: null };
	const formData = new FormData();
	formData.append('file', file, 'fileName.png');
	try {
		response.data = await axiosFileUploadInstance.post(
			COSMETICS_IMAGE_UPLOAD_API,
			formData
		);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

// status service
export const getTryOnFileService = async (queueId) => {
	const response = { error: '', data: null };
	const data = { id: queueId };
	try {
		response.data = await axiosInstance.post(COSMETICS_STATUS_API, data);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

// makeup try on service
export const sendColorCodeService = async (arr, id) => {
	const response = { error: '', data: null };
	const data = { filename: id, color: arr };
	try {
		response.data = await axiosInstance.post(LIVE_TRY_ON_API, data);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

// jewelry try on services
export const getEaringListService = async () => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.get(EARING_LIST_API);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

export const getRingListService = async () => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.get(RING_LIST_API);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

export const earingTryOnService = async (data) => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(EARING_TRY_ON_API, data);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

export const ringTryOnService = async (data) => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(RING_TRY_ON_API, data);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

// apparel try on services
export const getModelListService = async () => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(APPAREL_MODEL_LIST_API);
	} catch (err) {
		response.error = err?.response;
	}
	return response;
};

export const getClothesListService = async () => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(APPAREL_CLOTHES_LIST_API);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const uploadModelImageService = async (file) => {
	const response = { error: '', data: null };
	const formData = new FormData();
	formData.append('model', file, 'fileName.jpg');
	try {
		response.data = await axiosFileUploadInstance.post(
			APPAREL_MODEL_IMAGE_UPLOAD_API,
			formData
		);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const uploadModelStatusService = async (queueId) => {
	const response = { error: '', data: null };
	const data = { id: queueId };
	try {
		response.data = await axiosInstance.post(
			APPAREL_MODEL_IMAGE_STATUS_API,
			data
		);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const uploadClothesImageService = async (file) => {
	const response = { error: '', data: null };
	const formData = new FormData();
	formData.append('cloth', file, 'fileName.jpg');
	try {
		response.data = await axiosFileUploadInstance.post(
			APPAREL_CLOTHES_IMAGE_UPLOAD_API,
			formData
		);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const uploadClothesStatusService = async (queueId) => {
	const response = { error: '', data: null };
	const data = { id: queueId };
	try {
		response.data = await axiosInstance.post(
			APPAREL_CLOTHES_IMAGE_STATUS_API,
			data
		);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const apparelTryOnService = async (data) => {
	const response = { error: '', data: null };
	try {
		response.data = await axiosInstance.post(APPAREL_TRY_ON_API, data);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};

export const apparelStatusService = async (queueId) => {
	const response = { error: '', data: null };
	const data = { id: queueId };
	try {
		response.data = await axiosInstance.post(APPAREL_STATUS_API, data);
	} catch (err) {
		response.error = err?.response || err;
	}
	return response;
};
