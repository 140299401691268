const ExternalRedirect = (props) => {
	const { to, children, openLocation } = props;

	const handleClick = () => {
		if (openLocation === 'newTab') {
			window.open(to, '_blank');
			return;
		}
		window.location.href = to;
	};

	return (
		<div
			role='button'
			onClick={handleClick}
		>
			{children}
		</div>
	);
};

export default ExternalRedirect;
