import AIPrototypeSection from '../../Components/UnthinkableHomePage/AIPrototypeSection/AIPrototypeSection';
import MainBanner from '../../Components/UnthinkableHomePage/MainBanner/MainBanner';

const UnthinkableHomePage = () => {
	return (
		<>
			<MainBanner />
			<AIPrototypeSection />
		</>
	);
};

export default UnthinkableHomePage;
