import { useState } from 'react';

import ExpertBanner from '../../Components/ExpertBanner/ExpertBanner';
import ChooseVideoModal from '../../Components/ChooseVideoModal/ChooseVideoModal';
import TranscribedVideo from '../../Components/TrabscribedVideo/TranscribedVideo';
import VideoTranscriptionIntro from '../../Components/VideoTranscriptionIntro/VideoTranscriptionIntro';
import { uploadTranscribeVideoService } from '../../Services/videoTranscriptionService';
import { VIDEO_TRANSCRIBE } from '../../Constants/textContent';
import HowItWorks from '../../Components/HowItWorks/HowItWorks';

const VideoTranscription = () => {
	const [showSampleVideo, setShowSampleVideo] = useState(false);
	const [uploadedVideo, setUploadedVideo] = useState('');
	const [transcribedText, setTranscribedText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const closeModalHandler = () => {
		setShowSampleVideo(false);
	};

	const sampleVideoSelectHandler = (videoInfo) => {
		transcribeUploadedVideo(videoInfo);
		setUploadedVideo({
			...videoInfo,
		});
		setShowSampleVideo(false);
	};

	const transcribeUploadedVideo = async (fileInfo) => {
		setIsLoading(true);
		const response = await uploadTranscribeVideoService(
			fileInfo?.file_name,
			fileInfo?.file_path
		);
		if (response?.data) {
			setTranscribedText(response?.data?.data?.transcription);
		} else if (response?.error) {
			setIsError(true);
		}
		setIsLoading(false);
	};

	return (
		<div>
			{showSampleVideo && (
				<ChooseVideoModal
					closeBtnHandler={closeModalHandler}
					uploadBtnHandler={sampleVideoSelectHandler}
				/>
			)}

			{!uploadedVideo ? (
				<VideoTranscriptionIntro
					setShowSampleVideo={setShowSampleVideo}
					setUploadedVideo={setUploadedVideo}
					videoSelectHandler={sampleVideoSelectHandler}
				/>
			) : (
				<TranscribedVideo
					uploadedVideo={uploadedVideo}
					setUploadedVideo={setUploadedVideo}
					transcribedText={transcribedText}
					setTranscribedText={setTranscribedText}
					isLoading={isLoading}
					isError={isError}
				/>
			)}
			<HowItWorks
				heading={VIDEO_TRANSCRIBE?.howItWorks?.heading}
				subHeading={VIDEO_TRANSCRIBE?.howItWorks?.subHeading}
				list={VIDEO_TRANSCRIBE?.howItWorks?.list}
				displayImg={VIDEO_TRANSCRIBE?.howItWorks?.displayImg}
			/>
			{/* <ExpertBanner /> */}
		</div>
	);
};

export default VideoTranscription;
