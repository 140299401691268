import { useContext } from 'react';

import {
	STATIC_FILE,
	APPAREL_TRY_ON_STATIC_FILE,
} from '../../../../Constants/apiEndpoint';
import { SOMETHING_WENT_WRONG_TRY_FIX } from '../../../../Constants/errorMessage';

import {
	processedImage,
	processedImageError,
	processedImageStatus,
	selectProduct,
	toggleLoader,
} from '../../../../Context/VirtualTryOn/virtualTryOnActions';
import { virtualTryOnContext } from '../../../../Context/VirtualTryOn/VirtualTryOnProvider';
import {
	apparelStatusService,
	apparelTryOnService,
	getTryOnFileService,
	ringTryOnService,
} from '../../../../Services/cosmeticTryOnService';
import { callApiUntilSuccessOrError } from '../../../../Utils/helperFunctions';
import styles from './product-style.module.css';

const Product = (props) => {
	const { id, img, fileName, label, description } = props;

	const [state, dispatch] = useContext(virtualTryOnContext);

	const productClickHandler = () => {
		if (
			state?.selectedProduct?.category === 'makeup' &&
			state?.selectedProduct?.productId !== id
		) {
			dispatch(selectProduct(id, ''));
		} else if (state?.selectedProduct?.productId !== id) {
			dispatch(selectProduct(id, fileName));
			handleTryOn(state?.selectedProduct?.subCategory, fileName);
		}
	};

	const handleTryOn = async (type, fileName) => {
		dispatch(toggleLoader(true));
		let response;
		if (type === 'rings') {
			response = await ringTryOnService({
				hand: state?.image?.id,
				ring: fileName,
			});
		} else if (type === 'clothes') {
			response = await apparelTryOnService({
				cloth: fileName,
				person: state?.image?.id,
			});
		}
		callApiUntilSuccessOrError(
			TryOnStatus,
			TryOnCompletedFunc,
			TryOnSuccessFunc,
			TryOnErrorFunc,
			{ task_id: response?.data?.data?.task_id, type: type },
			50
		);
	};

	const TryOnStatus = async ({ task_id, type }) => {
		const response =
			type === 'clothes'
				? await apparelStatusService(task_id)
				: await getTryOnFileService(task_id);
		if (response?.data?.data?.percentage === '100') {
			return {
				status: 'completed',
				filePath:
					type === 'clothes'
						? `${APPAREL_TRY_ON_STATIC_FILE}${response?.data?.data?.file_path}`
						: `${STATIC_FILE}${response?.data?.data?.file_path}`,
			};
		} else if (
			response?.data?.data?.status === 'error' ||
			response?.error?.status === 500
		) {
			return {
				status: 'error',
				msg: SOMETHING_WENT_WRONG_TRY_FIX,
			};
		} else if (
			response?.error?.status === 400 ||
			response?.error?.status === 404
		) {
			return { status: 'error', msg: response?.error?.data?.Error };
		} else {
			return {
				status: 'success',
				percentage: response?.data?.data?.percentage,
			};
		}
	};

	const TryOnCompletedFunc = ({ filePath }) => {
		dispatch(processedImage(filePath));
	};

	const TryOnSuccessFunc = ({ percentage }) => {
		dispatch(processedImageStatus(percentage));
	};

	const TryOnErrorFunc = ({ msg }) => {
		dispatch(processedImageError(msg));
	};

	return (
		<div
			className={` ${styles.container}`}
			onClick={state?.image?.original ? productClickHandler : null}
		>
			<img
				className={`${styles.img} ${
					id === state?.selectedProduct?.productId ? styles.activeImg : ''
				}`}
				alt=''
				src={img}
			/>
			<p className={styles.label}>{label}</p>
			<p className={styles.description}>{description}</p>
		</div>
	);
};

export default Product;
