import React, { useContext } from "react";
import { virtualTryOnContext } from "../../Context/VirtualTryOn/VirtualTryOnProvider";
import styles from "./virtual_try_on_products-style.module.css";
import LEFT_ARROW_ICON from "../../Assets/img/left_arrow.svg";
import Products from "../VirtualTryOn/Products/Products";
import { TAB_WIDTH } from '../../Constants/constants';

export default function VirtualTryOnProducts({ screenWidth,isVisible }) {
  const [state] = useContext(virtualTryOnContext);

  return (
    <>
      {state?.firstUpload ? (
        screenWidth > TAB_WIDTH && (
          <div className={styles.intro}>
            <img className={styles.arrowIcon} src={LEFT_ARROW_ICON} alt="" />

            <p className={styles.introText}>
              Experience the future of shopping with virtual try-on
            </p>
          </div>
        )
      ) : (
        <Products isVisible={isVisible}/>
      )}
    </>
  );
}
