import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import {
  recommendationItemList,
  recommendationProductDescription,
} from "../../../Services/recommendationEngineService";
import { RECOMMENDATION_BASIS, sizes } from "../../../Constants/constants.js";
import { styles } from "./ProductDescriptionStyles.js";
import ProductsRecommendation from "../../../Components/Recommendation/ProductsRecommendation/ProductsRecommendation";

export default function ProductDescription() {
  const { id } = useParams();

  const [productDescription, setProductDescription] = useState();
  const [colorFeatures, setColorFeatures] = useState({
    isLoading: false,
    isError: false,
    products: [],
  });
  const [categoryFeatures, setCategoryFeatures] = useState({
    isLoading: false,
    isError: false,
    products: [],
  });
  const [patternFeatures, setPatternFeatures] = useState({
    isLoading: false,
    isError: false,
    products: [],
  });
  const [allFeatures, setAllFeatures] = useState({
    isLoading: false,
    isError: false,
    products: [],
  });

  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getProductDescription = async () => {
    const response = await recommendationProductDescription(id);
    if (response?.data?.status === 200) {
      setProductDescription(response?.data?.data?.data[0]);
    } else {
      setIsError(true);
    }
  };
  const getRecommendationItemList = async (id, basis) => {
    if (basis === RECOMMENDATION_BASIS?.COLOR_FEATURES) {
      setColorFeatures((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    } else if (basis === RECOMMENDATION_BASIS?.CATEGORY_FEATURES) {
      setCategoryFeatures((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    } else if (basis === RECOMMENDATION_BASIS?.PATTERN_FEATURES) {
      setPatternFeatures((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    } else {
      setAllFeatures((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    }

    const response = await recommendationItemList(id, basis);
    if (response?.data?.status === 200) {
      const currentProducts = response?.data?.data?.data;
      if (basis === RECOMMENDATION_BASIS?.COLOR_FEATURES) {
        setColorFeatures({
          products: currentProducts,
          isLoading: false,
          isError: false,
        });
      } else if (basis === RECOMMENDATION_BASIS?.CATEGORY_FEATURES) {
        setCategoryFeatures({
          products: currentProducts,
          isLoading: false,
          isError: false,
        });
      } else if (basis === RECOMMENDATION_BASIS?.PATTERN_FEATURES) {
        setPatternFeatures({
          products: currentProducts,
          isLoading: false,
          isError: false,
        });
      } else {
        setAllFeatures({
          products: currentProducts,
          isLoading: false,
          isError: false,
        });
      }
    } else {
      const products = [];
      if (basis === RECOMMENDATION_BASIS?.COLOR_FEATURES) {
        setColorFeatures((prevState) => ({
          ...prevState,
          isLoading: false,
          products: products,
        }));
      } else if (basis === RECOMMENDATION_BASIS?.CATEGORY_FEATURES) {
        setCategoryFeatures((prevState) => ({
          ...prevState,
          isLoading: false,
          products: products,
        }));
      } else if (basis === RECOMMENDATION_BASIS?.PATTERN_FEATURES) {
        setPatternFeatures((prevState) => ({
          ...prevState,
          isLoading: false,
          products: products,
        }));
      } else {
        setAllFeatures((prevState) => ({
          ...prevState,
          isLoading: false,
          products: products,
        }));
      }
    }
  };

  useEffect(() => {
    if (id) getProductDescription();
  }, [id]);

  useEffect(() => {
    if (id) {
      Promise.all([
        getRecommendationItemList(id, RECOMMENDATION_BASIS?.COLOR_FEATURES),
        getRecommendationItemList(id, RECOMMENDATION_BASIS?.PATTERN_FEATURES),
        getRecommendationItemList(id, RECOMMENDATION_BASIS?.CATEGORY_FEATURES),
        getRecommendationItemList(id, RECOMMENDATION_BASIS?.ALL_FEATURES),
      ])
        .then(() => {})
        .catch((error) => {});
    }
  }, [id]);

  return (
    <>
      <Grid sx={styles.productDescBox}>
        <Grid sx={styles.productDescGrid}>
          {isError ? (
            <Box sx={styles.errorMsg}>Something went wrong</Box>
          ) : (
            <Grid container sx={styles.productDescContainer}>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                sm={12}
                sx={styles.productImgGrid}
              >
                <img
                  src={`${process.env.REACT_APP_RECOMMENDATION_IMAGE_BASE_URL}${productDescription?.Image_path}`}
                  alt="product"
                  style={styles.productImage}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                sm={12}
                sx={styles.productDescription}
              >
                <Box>
                  <Typography sx={styles.productName}>
                    {productDescription?.prod_name}
                  </Typography>
                  <Typography sx={styles.productPrice}>
                    {`Rs. ${productDescription?.price}`}
                  </Typography>
                  <Typography sx={styles.productSizeAvailable}>
                    Sizes Available
                  </Typography>
                  <Box sx={styles.allSize}>
                    {sizes?.map((size) => (
                      <Box sx={styles.sizeCircle} key={size}>
                        {size}
                      </Box>
                    ))}
                  </Box>
                  <Typography sx={styles.descriptionText}>
                    Short Description
                  </Typography>

                  <Typography sx={styles.descriptionData}>
                    {productDescription?.detail_desc}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!isError && (
          <>
            <ProductsRecommendation
              colorFeatures={colorFeatures}
              categoryFeatures={categoryFeatures}
              patternFeatures={patternFeatures}
              allFeatures={allFeatures}
              titleStyle={styles.productRecommendText}
            />
          </>
        )}
      </Grid>
    </>
  );
}
