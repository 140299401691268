import styles from './backdrop-styles.module.css';

const Backdrop = (props) => {
	const { customStyles, clickHandler } = props;
	return (
		<div
			onClick={clickHandler}
			className={`${styles.backdrop} ${customStyles}`}
		></div>
	);
};

export default Backdrop;
