export default function TryOnLoader({ value }) {
	const percentageValue = Number(value);
	return (
		<>
			{value === '100' ? (
				''
			) : (
				<div className='parent color2'>
					<div className='loader loader6'>
						<div className='loader6inner'></div>
						<div className='loader6inner'></div>
						<div className='loader6inner'></div>
						<div
							className='processing'
							style={{ color: 'white' }}
							id='processing'
						>
							{percentageValue || 0}%
						</div>
					</div>
				</div>
			)}
		</>
	);
}
