import { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ChatMessage from '../ChatMessage/ChatMessage';
import InputBoxChat from '../InputBoxChat/InputBoxChat';
import Speech from '../Speech/Speech';
import { textSendService } from '../../../Services/voiceAssistantService';
import styles from './chat_bot-style.module.css';

const initialMessage = [
	{
		id: uuidv4(),
		text: 'Hello Max, what can I help you with?',
		send: false,
	},
];

export default function ChatBot() {
	const dummy = useRef();

	const [formValue, setFormValue] = useState('');
	const [isSpeech, setSpeech] = useState(true);
	const [isMicrophoneActive, setIsMicrophoneActive] = useState(false);
	const [messages, setMessage] = useState([]);

	const handleClick = () => {
		var SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition;
		var recognition = new SpeechRecognition();
		recognition.continuous = true;
		setIsMicrophoneActive(true);
		recognition.start();

		recognition.onresult = function (e) {
			var transcript = e.results[0][0].transcript;
			setFormValue(transcript);
			recognition.stop();
			setIsMicrophoneActive(false);
		};
		return recognition;
	};

	const sendMessage = async (e) => {
		if (typeof e == 'object') {
			e.preventDefault();
		}
		if (formValue) {
			messages.push({ id: uuidv4(), text: formValue, send: true });
			setMessage([...messages]);
		} else {
			messages.push({ id: uuidv4(), text: e, send: true });
			setMessage([...messages]);
		}
		let value;
		if (formValue) {
			value = formValue;
		} else {
			value = e;
		}
		messages.push({ id: uuidv4(), isLoading: true });
		setMessage([...messages]);
		const response = await textSendService(value);

		if (response?.error) {
			messages.pop();
			messages.push({
				id: uuidv4(),
				isError: true,
				send: false,
				text: 'Something went wrong! Please try again.',
			});
			setMessage(messages);
		} else {
			if (response?.data?.data?.isoption) {
				messages.pop();
				messages.push({
					id: uuidv4(),
					text: response?.data?.data?.data,
					send: false,
					isoptions: true,
					options: response?.data?.data?.options?.map((item, idx) => {
						return {
							id: idx,
							text: item,
							isDisabled: false,
							isSelected: false,
						};
					}),
					isActive: true,
				});
				setMessage([...messages]);
				dummy.current.scrollIntoView({ behavior: 'smooth' });
				const synth = window.speechSynthesis;
				const utterThis = new SpeechSynthesisUtterance();
				var voices = window.speechSynthesis.getVoices();
				utterThis.rate = 1.25;
				utterThis.text = response?.data?.data?.data;
				synth.speak(utterThis);
				if (formValue) {
					setFormValue('');
				}
				return;
			}

			messages.pop();
			messages.push({
				id: uuidv4(),
				text: response?.data?.data?.data,
				send: false,
			});
			setMessage([...messages]);
			const synth = window.speechSynthesis;

			const utterThis = new SpeechSynthesisUtterance();
			var voices = window.speechSynthesis.getVoices();
			utterThis.rate = 1.25;
			utterThis.text = response?.data?.data?.data;
			synth.speak(utterThis);
			if (formValue) {
				setFormValue('');
			}
			// dummy.current && dummy.current.scrollTop();
		}
	};

	useEffect(() => {
		if (dummy) {
			dummy.current.addEventListener('DOMNodeInserted', (event) => {
				const { currentTarget: target } = event;
				target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
			});
		}
	}, []);

	useEffect(() => {
		if (formValue) {
			sendMessage();
		}
	}, [formValue]);

	useEffect(() => {
		setMessage([{ isLoading: true }]);
		setTimeout(() => {
			messages.pop();
			setMessage([...initialMessage]);
		}, 2000);
	}, []);

	return (
		<>
			<div>
				<div className={styles.wrapper}>
					<div className={styles.header}>Book Appointment</div>
					<div className={styles.contentWrapper}>
						<main ref={dummy}>
							{messages &&
								messages?.map((msg, index) => {
									return (
										<ChatMessage
											key={index}
											index={index}
											message={msg}
											fullMsg={messages}
											setMessage={setMessage}
											sendMessage={sendMessage}
										/>
									);
								})}
							{messages?.length < 3 && (
								<div className={styles.backdropText}>
									Tap on the microphone icon to speak
								</div>
							)}
						</main>
					</div>
					<div>
						{!isSpeech ? (
							<InputBoxChat
								setSpeech={setSpeech}
								setFormValue={setFormValue}
								handleClick={handleClick}
							></InputBoxChat>
						) : (
							<Speech
								setSpeech={setSpeech}
								handleClick={handleClick}
								isMicrophoneActive={isMicrophoneActive}
							></Speech>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
