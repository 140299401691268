export const WILDCARD = "*";
export const ROOT = "/";

export const UNTHINKABLE_HOME = "/unthinkable";
export const VIDEO_TRANSCRIBE = "/video-transcription";
export const VIRTUAL_MAKEUP = "/virtual-try-on";
export const VOICE_ASSISTANT = "/voice-assistant";
export const RECOMMENDATION_ENGINE = "/recommendation-engine";
export const RECOMMENDATION_ENGINE_PRODUCTS_LIST =
  "/recommendation-engine/products-list";
export const RECOMMENDATION_ENGINE_PRODUCT_DESCRIPTION =
  "/recommendation-engine/product-description/:id";
