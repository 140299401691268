import { FELWOOD_LEAVES, SPARKLING_COSMO } from "../../../Constants/colors";

export const styles = {
  descriptionText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    "@media (max-width:767px) ": {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  productImageStyle: {
    width: "254px",
    height: "394px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginBottom: "11px",
    cursor: "pointer",
    borderRadius: "10px",
    "@media (max-width:767px) ": {
      width: "150px",
      height: "250px",
    },
  },
  productContainer: {
    maxWidth: "1256px",
    justifyContent: "flex-start",
    "@media (min-width:768px) and (max-width:991px)": {
      maxWidth: "100%",
      padding: "0px 90px 90px 90px",
    },
    "@media (max-width:767px)": {
      padding: "0px 30px 30px 30px",
    },
  },
  product: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "254px !important",
    width: "254px",
    "@media (max-width:767px) ": {
      maxWidth: "150px !important",
    },
  },
  productGridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  headingText: {
    fontFamily: "Inter, sans-serif",
    textAlign: "left",
    display: "block",
    fontSize: "24px",
    lineHeight: "22px",
    fontWeight: 500,
    width: "100%",
    "@media (min-width:768px) and (max-width:991px)": {
      maxWidth: "100%",
    },
  },
  errorMsg: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "141.7%",
    color: SPARKLING_COSMO,
    margin: "123px auto",
    width: "85%",
    textAlign: "center",
    padding: "46px 10px",
    borderTop: `2px solid ${SPARKLING_COSMO}`,
    borderBottom: `2px solid ${SPARKLING_COSMO}`,
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "40px",
  },
  loaderStyle: {
    width: "100px",
    height: "100px",
  },
  scoreText: {
    color: FELWOOD_LEAVES,
  },
};
