export const SOMETHING_WENT_WRONG = 'Something went wrong!';
export const FILE_TOO_LARGE =
	'File size is too large. Upload a file less than 20MB in size';
export const VIDEO_DURATION_TOO_LARGE =
	'Please upload short duration Video , it exceed limit of 60 seconds duration';
export const IMAGE_FILE_TOO_LARGE =
	'File size is too large. Upload a file less than 1MB in size';
export const FILE_UPLOAD_ERR =
	'Something went wrong! Cannot upload your video right now. Try again later.';
export const VIDEO_FETCH_ERR =
	'Something went wrong! Cannot fetch template videos. Try again later.';
export const SOMETHING_WENT_WRONG_TRY_FIX =
	'Something went wrong! Please wait while we are working to fix this problem.';
