import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Backdrop from '../Backdrop/Backdrop';
import Player from '../Player/Player';
import CustomButton from '../Widgets/CustomButton/CustomButton';
import CANCEL_ICON from '../../Assets/img/Cancel Icon-2.svg';
import UNFILLED_CHECKBOX_ICON from '../../Assets/img/checkbox-unfilled.svg';
import FILLED_CHECKBOX_ICON from '../../Assets/img/checkbox-filled.svg';
import styles from './choose_video_modal-styles.module.css';
import { VIDEO_FETCH_ERR } from '../../Constants/errorMessage';
import { VIDEO_BASE_URL } from '../../Constants/links';
import { getDummyVideoService } from '../../Services/videoTranscriptionService';

const ChooseVideoModal = (props) => {
	const { closeBtnHandler, uploadBtnHandler } = props;

	const [selectedVideo, setSelectedVideo] = useState('');
	const [sampleVideos, setSampleVideos] = useState([]);

	const videoSelectHandler = (id) => {
		setSelectedVideo(id);
	};

	const fetchDummyVideo = async () => {
		const response = await getDummyVideoService();
		if (response?.data) {
			const video = response?.data?.data?.links;
			setSampleVideos(
				video
					?.filter((data) => data?.file_name.includes('.mp4'))
					?.map((data, idx) => {
						return {
							id: idx + 1,
							...data,
							link: `${VIDEO_BASE_URL}${data?.file_name}`,
						};
					})
			);
		} else if (response?.error) {
			toast.error(VIDEO_FETCH_ERR);
			closeBtnHandler();
		}
	};

	useEffect(() => {
		fetchDummyVideo();
	}, []);

	return (
		<div className={styles.container}>
			<Backdrop customStyles={styles.backdrop}/>
			<div className={styles.wrapper}>
				<div
					className={styles.cancelIconWrapper}
					onClick={closeBtnHandler}
				>
					<img
						src={CANCEL_ICON}
						alt='cancel'
						className={styles.cancelIcon}
					/>
				</div>
				<div className={styles.header}>
					<div>
						<h4 className={styles.heading}>
							Choose a video from below templates
						</h4>
						<p className={styles.description}>
							Try On the below videos to test the prototype
						</p>
					</div>
				</div>
				<div className={styles.videoContainer}>
					{sampleVideos?.map((data) => {
						return (
							<div
								key={data?.id}
								className={`${styles.videoWrapper} ${
									selectedVideo === data?.id && styles.videoWrapperActive
								}`}
								onClick={() => videoSelectHandler(data?.id)}
							>
								<img
									onClick={() => videoSelectHandler(data?.id)}
									className={styles.checkboxIcon}
									src={
										selectedVideo === data?.id
											? FILLED_CHECKBOX_ICON
											: UNFILLED_CHECKBOX_ICON
									}
									alt=''
								/>
								<Player
									videoSrc={data?.link}
									controls
									thumbnail={data?.thumbnail}
									customStyles={styles.video}
								/>
							</div>
						);
					})}
				</div>
				<div className={styles.bottomBtnWrapper}>
					<CustomButton
						text='Upload'
						customStyles={`${styles.btn} ${styles.uploadBtn} ${
							!selectedVideo && styles.disabledBtn
						}`}
						clickHandler={() =>
							selectedVideo && uploadBtnHandler(sampleVideos[selectedVideo - 1])
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default ChooseVideoModal;
