import HomePage from "../Pages/HomePage/HomePage";
import UnthinkableHomePage from "../Pages/UnthinklableHomePage/UnthinkableHomePage";
import VideoTranscription from "../Pages/VideoTranscription/VideoTranscription";
import VirtualTryOn from "../Pages/VirtualTryOn/VirtualTryOn";
import VoiceAssistant from "../Pages/VoiceAssistant/VoiceAssistant";
import ProductsListContainer from "../Pages/Recommendation/ProductsListContainer/ProductsListContainer";
import ProductDescription from "../Pages/Recommendation/ProductDescription/ProductDescription";
import LandingPage from "../Pages/Recommendation/LandingPage/LandingPage";
import {
  VOICE_ASSISTANT,
  ROOT,
  VIDEO_TRANSCRIBE,
  VIRTUAL_MAKEUP,
  UNTHINKABLE_HOME,
  RECOMMENDATION_ENGINE_PRODUCTS_LIST,
  RECOMMENDATION_ENGINE_PRODUCT_DESCRIPTION,
  RECOMMENDATION_ENGINE,
} from "./Routes";

export const publicRoutes = [
  {
    id: 1,
    path: ROOT,
    component: <UnthinkableHomePage />,
    isLayoutNeeded: true,
  },
  {
    id: 2,
    path: VIDEO_TRANSCRIBE,
    component: <VideoTranscription />,
    isLayoutNeeded: true,
  },
  {
    id: 3,
    path: VIRTUAL_MAKEUP,
    component: <VirtualTryOn />,
    isLayoutNeeded: true,
  },
  {
    id: 4,
    path: VOICE_ASSISTANT,
    component: <VoiceAssistant />,
    isLayoutNeeded: true,
  },
  {
    id: 5,
    path: UNTHINKABLE_HOME,
    component: <UnthinkableHomePage />,
    isLayoutNeeded: true,
  },
  {
    id: 6,
    path: RECOMMENDATION_ENGINE,
    component: <LandingPage />,
    isLayoutNeeded: true,
  },
  {
    id: 6,
    path: RECOMMENDATION_ENGINE_PRODUCTS_LIST,
    component: <ProductsListContainer />,
    isLayoutNeeded: false,
  },
  {
    id: 7,
    path: RECOMMENDATION_ENGINE_PRODUCT_DESCRIPTION,
    component: <ProductDescription />,
    isLayoutNeeded: false,
  },
];
