import {
  BLACK,
  BLACK_TIE,
  CAPE_HOPE,
  SONIC_SILVER,
  SPARKLING_COSMO,
  WHITE,
} from "../../../Constants/colors.js";

export const styles = {
  productImgGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productDescBox: {
    paddingTop: "0",
    marginBottom: "50px",
  },
  productImage: {
    objectFit: "contain",
    width: "100%",
  },
  productDescGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "149px",
    "@media(min-width:768px) and (max-width:991px) ": {
      alignItems: "start",
      justifyContent: "start",
      padding: "149px 90px 90px 90px",
      marginTop: "0px",
    },
    "@media(max-width:767px)": {
      padding: "100px 30px 30px 30px",
      marginTop: "0px",
    },
  },
  productDescContainer: {
    maxWidth: "1256px",
    "@media screen and (min-width:768px) and (max-width:991px)": {
      minWidth: "100%",
      marginX: "0px",
    },
  },
  productName: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "22px",
    marginBottom: "22px",
    color: BLACK,
    "@media(min-width:768px) and (max-width:991px) ": {
      marginTop: "20px",
    },
  },
  productPrice: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "22px",
    marginBottom: "63px",
    color: BLACK,
  },
  productSizeAvailable: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "10px",
    color: BLACK_TIE,
  },
  descriptionText: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "8px",
    color: BLACK_TIE,
  },
  descriptionData: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#A5A5A5",
    width: "489px",
    "@media (max-width:992px) ": {
      width: "100%",
    },
  },
  productDescription: {
    paddingLeft: "42px",
    marginTop: "5px",
    "@media (max-width:992px) ": {
      paddingLeft: "0px",
      marginTop: "20px",
    },
  },
  sizeCircle: {
    fontFamily: "Inter, sans-serif",
    borderRadius: "50%",
    background: WHITE,
    border: `1px solid ${CAPE_HOPE}`,
    padding: "15px",
    color: SONIC_SILVER,
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    marginRight: "10px",
    width: "53px",
    height: "53px",
    textAlign: "center",
  },
  allSize: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "63px",
  },
  productRecommendText: {
    marginTop: "98px",
  },
  errorMsg: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "141.7%",
    color: SPARKLING_COSMO,
    margin: "123px auto",
    width: "85%",
    textAlign: "center",
    padding: "46px 10px",
    borderTop: `2px solid ${SPARKLING_COSMO}`,
    borderBottom: `2px solid ${SPARKLING_COSMO}`,
  },
};
