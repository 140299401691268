import ProductsList from "../../../Pages/Recommendation/ProductsList/ProductsList";
import { RECOMMENDATION_BASIS } from "../../../Constants/constants";

export default function ProductsRecommendation(props) {
  const {
    colorFeatures,
    patternFeatures,
    categoryFeatures,
    allFeatures,
    titleStyle,
  } = props;
  return (
    <>
      {colorFeatures?.products?.length > 0 && (
        <ProductsList
          title="Similar in Color"
          products={colorFeatures?.products}
          rowGap={3}
          titleStyle={titleStyle}
          isError={colorFeatures?.isError}
          isLoading={colorFeatures?.isLoading}
          basis={RECOMMENDATION_BASIS?.COLOR_FEATURES}
        />
      )}

      {patternFeatures?.products?.length > 0 && (
        <ProductsList
          title="Similar in Pattern"
          products={patternFeatures?.products}
          rowGap={3}
          titleStyle={titleStyle}
          isError={patternFeatures?.isError}
          isLoading={patternFeatures?.isLoading}
          basis={RECOMMENDATION_BASIS?.PATTERN_FEATURES}
        />
      )}

      {categoryFeatures?.products?.length > 0 && (
        <ProductsList
          title="Similar in Category"
          products={categoryFeatures?.products}
          rowGap={3}
          titleStyle={titleStyle}
          isError={categoryFeatures?.isError}
          isLoading={categoryFeatures?.isLoading}
          basis={RECOMMENDATION_BASIS?.CATEGORY_FEATURES}
        />
      )}

      {allFeatures?.products?.length > 0 && (
        <ProductsList
          title="Similar in Description"
          products={allFeatures?.products}
          rowGap={3}
          titleStyle={titleStyle}
          isError={allFeatures?.isError}
          isLoading={allFeatures?.isLoading}
          basis={RECOMMENDATION_BASIS?.ALL_FEATURES}
        />
      )}
    </>
  );
}
