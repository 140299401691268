import MainBanner from '../../Components/MainBanner/MainBanner';
import SuccessStory from '../../Components/SuccessStory/SuccessStory';
import VideoTranscriptionSection from '../../Components/VideoTranscriptionSection/VideoTranscriptionSection';
import VirtualTryOnSection from '../../Components/VirtualTryOnSection/VirtualTryOnSection';
import VoiceAssistantSection from '../../Components/VoiceAssistantSection/VoiceAssistantSection';
import ExpertBanner from '../../Components/ExpertBanner/ExpertBanner';

const HomePage = () => {
	return (
		<div>
			<MainBanner />
			<VirtualTryOnSection />
			<VoiceAssistantSection />
			<VideoTranscriptionSection />
			<ExpertBanner />
			<SuccessStory />
		</div>
	);
};

export default HomePage;
