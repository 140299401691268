import { SOMETHING_WENT_WRONG } from '../../Constants/errorMessage';
import * as types from './types';

export const selectCategory = (category, subCategory) => {
	return {
		type: types.VIRTUAL_TRY_ON_SELECT_CATEGORY,
		payload: {
			image: {
				original: '',
				file: '',
				id: '',
				isLoading: false,
				isError: false,
				status: '',
			},
			selectedProduct: {
				category: category,
				subCategory: subCategory,
				fileName: '',
				productId: '',
				colorCode: [],
			},
		},
	};
};

export const clearImage = () => {
	return {
		type: types.VIRTUAL_TRY_ON_CLEAR_IMAGE,
		payload: {
			original: '',
			file: '',
			id: '',
			isLoading: false,
			isError: false,
			status: '',
		},
	};
};

export const uploadImage = (id, imgFile) => {
	return {
		type: types.VIRTUAL_TRY_ON_UPLOAD_IMAGE,
		payload: {
			firstUpload: false,
			image: {
				original: imgFile,
				file: imgFile,
				id: id,
				isLoading: false,
				isError: false,
				status: '',
			},
		},
	};
};

export const selectProduct = (id, fileName) => {
	return {
		type: types.VIRTUAL_TRY_ON_SELECT_PRODUCT,
		payload: {
			productId: id,
			fileName: fileName,
			colorCode: [],
		},
	};
};

export const toggleLoader = (loadingStatus) => {
	return {
		type: types.VIRTUAL_TRY_ON_TOGGLE_LOADER,
		payload: {
			isLoading: loadingStatus,
		},
	};
};

export const processedImage = (imageUrl) => {
	return {
		type: types.VIRTUAL_TRY_ON_PROCESSED_IMAGE,
		payload: {
			file: imageUrl,
			isLoading: false,
			status: '',
		},
	};
};

export const processedImageError = (errorMsg = SOMETHING_WENT_WRONG) => {
	return {
		type: types.VIRTUAL_TRY_ON_ERROR,
		payload: {
			isLoading: false,
			status: '',
			errorMsg: errorMsg,
		},
	};
};

export const processedImageStatus = (status) => {
	return {
		type: types.VIRTUAL_TRY_ON_STATUS,
		payload: {
			status: status,
			isLoading: true,
		},
	};
};

export const selectColorCode = (code) => {
	return {
		type: types.VIRTUAL_TRY_ON_SELECT_COLOR,
		payload: {
			colorCode: code,
		},
	};
};

export const selectModel = (id, imgUrl) => {
	return {
		type: types.VIRTUAL_TRY_ON_SELECT_MODEL,
		payload: {
			image: {
				original: imgUrl,
				file: imgUrl,
				id: id,
			},
			selectedProduct: {
				productId: '',
			},
		},
	};
};

export const uploadClothImage = (id, imgUrl) => {
	return {
		type: types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE,
		payload: {
			id: id,
			file: imgUrl,
			uploadModal: false,
			isLoading: false,
		},
	};
};

export const uploadClothImageLoader = (isLoading) => {
	return {
		type: types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE_LOADER,
		payload: {
			isLoading: isLoading,
		},
	};
};

export const uploadClothModal = (showModal) => {
	return {
		type: types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_MODAL,
		payload: {
			uploadModal: showModal,
		},
	};
};

export const removeClothImage = () => {
	return {
		type: types.VIRTUAL_TRY_ON_REMOVE_CLOTH_IMAGE,
		payload: {
			clothImage: {
				id: '',
				file: '',
			},
			image: {
				file: '',
			},
		},
	};
};
