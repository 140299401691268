// import VOICE_ASSISTANT_HOW_IT_WORKS_IMG from '../Assets/img/how-it-works-3.webp';
// import VIDEO_TRANSCRIPTION_HOW_IT_WORKS_IMG from '../Assets/img/how-it-works-1.webp';
// import VIRTUAL_TRY_ON_HOW_IT_WORKS_IMG from '../Assets/img/how-it-works-2.webp';
import VOICE_ASSISTANT_HOW_IT_WORKS_IMG from '../Assets/img/voice-assistant-ai-used.webp';
import VIDEO_TRANSCRIPTION_HOW_IT_WORKS_IMG from '../Assets/img/ai-used.webp';
import MAKEUP_ICON from '../Assets/img/Group 8210.svg';
import RING_ICON from '../Assets/img/overlay-ring-dummy.svg';
import APPAREL_ICON from '../Assets/img/overlay-clothes-icon.svg';
import CLOTH_UPLOAD_IMG from '../Assets/img/cloth_upload.png';
import CHOOSE_MODEL from '../Assets/img/chooseModel1.svg';
import VIRTUAL_TRY_ON_HOW_IT_WORKS_IMG from '../Assets/img/Group 8158.webp';
import WORK_RECOMMENDATION from '../Assets/img/work_recommend_unthinkable.webp';

export const HOMEPAGE_CONTENT = {
	aiService: {
		heading: 'AI-Enabled Services',
		description:
			'Here is a glimpse of our progressive, scalable AI-based solutions that deliver superior performance, fast. Personalize customer experience and unlock new ways of working with AI services designed to create maximum business impact.',
	},
	virtualTryOn: {
		heading: 'Virtual-try on',
		description:
			"Elevate your customer's shopping experience with our virtual try-on for makeup & jewelry. Our hyper-realistic try-on services provide a modern & personalized experience at point-of-sale that enables customers to visualize their final looks before committing to buying a product. Our virtual try-on solutions are scalable and can be adapted to other products such as clothes, contact lenses and some other makeup products as per clients' requirements.",
		btn: 'Try demo now',
	},
	voiceAssistant: {
		heading: 'Voice Assistant',
		description:
			'Facilitate an easy & seamless appointment booking experience for your customers with our conversational AI solutions. Built using advanced NLP and ML, our voice assistant is intuitive and easy to use. The following demo is a mere sample for your reference, the final solution is designed based on client requirements and challenges.',
		btn: 'Try demo now',
	},
	videoTranscription: {
		heading: 'Video Transcription',
		description:
			"Automatically transcribe video to text with our video transcription solutions. Powered by the latest AI technology, our solution can achieve more than 80% accuracy, depending on the audio quality and the language being spoken. The same capabilities can also be extended to speech-to-text transcription as per the client's business needs.",
		btn: 'Try demo now',
	},
};

export const VOICE_ASSISTANT = {
	heading: 'Voice Assistant',
	list: [
		'The following demo is designed to enable seamless and quick medical appointment booking for patients.',
		'Our virtual assistant can easily be integrated with your existing app or can be designed as a stand-alone application.',
		"Our virtual assistant can also be adapted to perform different functions as per the client's requirements.",
	],
	howItWorks: {
		heading: 'How AI is being used?',
		subHeading: '',
		list: [
			'Our AI-enabled voice assistant works by using natural language processing (NLP) technology to understand and interpret spoken commands and questions.',
			'When you speak to the AI voice assistant, your words are converted into text by a speech recognition system.',
			"The text is then analyzed by the voice assistant's NLP algorithms, which attempt to understand the meaning and intent behind your words.",
			'Based on this understanding, the voice assistant performs the appropriate action or provides the requested information.',
		],
		displayImg: VOICE_ASSISTANT_HOW_IT_WORKS_IMG,
	},
};

export const VIRTUAL_TRY_ON = {
	howItWorks: {
		heading: 'How AI is being used?',
		subHeading: '',
		list: [
			'Our AI-enabled virtual try-on solutions are designed to allows users to virtually try on makeup and jewelry.',
			"Developed using computer vision and machine learning, the algorithm analyze a user's face and generate a 3D model.",
			"The user can then select an item to try on, and the AI algorithms will adjust the item to fit the user's virtual image.",
			'Our AI model can also be replicated for other products such as clothes, makeup items, contact lenses, glasses etc.',
		],
		displayImg: VIRTUAL_TRY_ON_HOW_IT_WORKS_IMG,
	},
	overlay: {
		lipstick: {
			img: MAKEUP_ICON,
			text: [
				'Upload or capture an image which shows your face clearly to try on the lipsticks',
				'Select the product from our catalog to try it virtually',
				'Try out different lipsticks with multiple shades available',
			],
		},
		rings: {
			img: RING_ICON,
			text: [
				'Upload or capture an image which clearly shows your fingers to try on the rings',
				'Select the product from our catalog to try it virtually',
				'Try out different rings',
			],
		},
		clothes: {
			img: CHOOSE_MODEL,
			text: [
				`Upload at least your full upper body picture clearly in a well-lit background or you can select an appropriate model from our “Choose Model” option.`,
				'Stand in portrait mode without wearing accessories or having any person or object in the image.',
				'Select the apparel from our catalog to try it virtually.',
			],
		},
	},
	apparel: {
		clothUploadModal: {
			headerLabel: 'Upload Cloth',
			textList: [
				'Upload a cloth image properly in a portrait form facing a light source, with an even, and noise-free background.',
				'The whole cloth should be covered & spread widely, with the background & image having the appropriate contrast.',
				'The cloth should not contain any object with it such as a hanger, etc.',
			],
			sideImg: CLOTH_UPLOAD_IMG,
		},
	},
};

export const VIDEO_TRANSCRIBE = {
	howItWorks: {
		heading: 'How AI is being used?',
		subHeading: '',
		list: [
			'The audio from the video is first extracted and converted into a digital format.',
			'The audio is then filtered and conditioned to remove noise and improve the signal-to-noise ratio.',
			'The audio is then processed using automated speech recognition (ASR) technology to convert the spoken words into text.',
			'Additional machine learning techniques, such as natural language processing (NLP) and machine translation are used to improve the accuracy of the transcript.',
			'The transcription model can also be used for speech-to-text transcription.',
		],
		displayImg: VIDEO_TRANSCRIPTION_HOW_IT_WORKS_IMG,
	},
};

export const RECOMMENDATION_ENGINE_WORK = {
	howItWorks: {
		heading: 'How AI is being used?',
		subHeading: '',
		list: [
			'Leveraging machine learning algorithms, user behavior is analyzed based on data from past purchases, viewed items, and search activity.',
			"The AI algorithms then process the collected data and identify patterns and trends in a user's behavior for generating tailored recommendations.",
			'Our AI model implements some advanced recommendation algorithms including collaborative filtering, matrix factorization, association rules, and logistic regression.',
			"Various evaluation metrics are also employed to measure the effectiveness of the model, depending on the system's goals and algorithm type.",
			'Some common metrics include conversion rates, CTR, personalization accuracy, churn rate, and much more.',
			'As users engage with the model, it continuously learns more about their preferences, enabling it to refine its recommendations and improve its predictive capabilities over time.',
		],
		displayImg: WORK_RECOMMENDATION,
	},
};

export const EXPERT_BANNER = {
	headingPrimary: 'Get a custom consultation now!',
	headingSecondary: 'Try our AI services now!',
};
