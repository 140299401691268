import { Box, Grid, Typography } from "@mui/material";

import EMAIL from "../../../Assets/img/email.svg";
import RE_LOGO_LARGE from "../../../Assets/img/logo-no-background.svg";
import { styles } from "./FooterStyles.js";

export default function Footer() {
  return (
    <>
      <Box sx={styles.footerContainer}>
        <Grid container sx={styles.gridContainer}>
          <Grid item sx={styles.reLogoContainer} md={4}>
            <Typography sx={styles.reLogo}>
              <Typography
                component="img"
                src={RE_LOGO_LARGE}
                sx={styles.logoImg}
              />
            </Typography>
          </Grid>
          <Grid item sx={styles.aboutUsContainer} md={8}>
            <Typography sx={styles.aboutText}>About Us</Typography>
            <Typography sx={styles.aboutData}>
              Shopping has never been more fun and effortless. With a smart
              recommendation engine, our solution analyzes your unique shopping
              habits to generate personalized product suggestions that match
              your needs and preferences. The more you use our solution, the
              smarter it becomes, allowing you to discover new items that fit
              your style.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.contactUsContainer}>
        <img src={EMAIL} alt="email" style={styles.emailImage} />
        info@daffodilsw.com
      </Box>
    </>
  );
}
