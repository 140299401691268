import React from "react";
import LINKDIN from "../../../../Templates/Unthinkable/images/linkedin.webp";
import LINKDIN_HOVER from "../../../../Templates/Unthinkable/images/linkedinHover.webp";

import INSTAGRAM from "../../../../Templates/Unthinkable/images/instagram.webp";
import YOUTUBE from "../../../../Templates/Unthinkable/images/youtube.webp";
import TWITTER from "../../../../Templates/Unthinkable/images/twitter.webp";
import "../footer-styles.css";

export default function FooterIcons() {
  return (
    <div className="icons">
      <a
        href="https://www.linkedin.com/company/unthinkable-software/mycompany/"
        target="_blank"
      >
        <div className="linkedin"></div>
      </a>
      <a href="https://twitter.com/UnthinkableSW" target="_blank">
        {/* <img src={TWITTER} alt="" /> */}
        <div className="twitter"></div>
      </a>
      <a
        href=" https://www.youtube.com/channel/UCvBmiZnmq1kVge9aF3NB73g/videos?view_as=subscriber"
        target="_blank"
      >
        <div className="youtube"></div>
        {/* <img src={YOUTUBE} alt="" /> */}
      </a>
      <a
        href=" https://www.instagram.com/unthinkable_software/?igshid=OGQ2MjdiOTE%3D"
        target="_blank"
      >
        <div className="instagram"></div>

        {/* <img src={INSTAGRAM} alt="" /> */}
      </a>
    </div>
  );
}
