export const VIRTUAL_TRY_ON_SELECT_CATEGORY = 'VIRTUAL_TRY_ON_SELECT_CATEGORY';
export const VIRTUAL_TRY_ON_CLEAR_IMAGE = 'VIRTUAL_TRY_ON_CLEAR_IMAGE';
export const VIRTUAL_TRY_ON_UPLOAD_IMAGE = 'VIRTUAL_TRY_ON_UPLOAD_IMAGE';
export const VIRTUAL_TRY_ON_SELECT_PRODUCT = 'VIRTUAL_TRY_ON_SELECT_PRODUCT';
export const VIRTUAL_TRY_ON_TOGGLE_LOADER = 'VIRTUAL_TRY_ON_TOGGLE_LOADER';
export const VIRTUAL_TRY_ON_PROCESSED_IMAGE = 'VIRTUAL_TRY_ON_PROCESSED_IMAGE';
export const VIRTUAL_TRY_ON_ERROR = 'VIRTUAL_TRY_ON_ERROR';
export const VIRTUAL_TRY_ON_STATUS = 'VIRTUAL_TRY_ON_STATUS';
export const VIRTUAL_TRY_ON_SELECT_COLOR = 'VIRTUAL_TRY_ON_SELECT_COLOR';
export const VIRTUAL_TRY_ON_SELECT_MODEL = 'VIRTUAL_TRY_ON_SELECT_MODEL';
export const VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE =
	'VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE';
export const VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE_LOADER =
	'VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE_LOADER';
export const VIRTUAL_TRY_ON_UPLOAD_CLOTH_MODAL =
	'VIRTUAL_TRY_ON_UPLOAD_CLOTH_MODAL';
export const VIRTUAL_TRY_ON_REMOVE_CLOTH_IMAGE =
	'VIRTUAL_TRY_ON_REMOVE_CLOTH_IMAGE';
