import CustomButton from "../../../Components/Widgets/CustomButton/CustomButton";
import HowItWorks from "../../../Components/HowItWorks/HowItWorks";
import ExternalRedirect from "../../../Components/ExternalRedirect/ExternalRedirect";
import RECOMMENDATION from "../../../Assets/img/recommendUnthinkable.webp";
import { RECOMMENDATION_ENGINE_WORK } from "../../../Constants/textContent";
import { RECOMMENDATION_ENGINE_PRODUCTS_LIST } from "../../../Routes/Routes";
import { TAB_WIDTH } from "../../../Constants/constants";
import useScreenWidth from "../../../Hooks/useScreenWidth";
import styles from "./landing-page_style.module.css";

export default function LandingPage() {
  const [screenWidth] = useScreenWidth();

  return (
    <>
      <div className={styles.landingPage}>
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-6 col-md-6 col-sm-12 ${styles.textContainer}`}
            >
              <div className={styles.recommendationTextImage}>
                <h4 className={styles.heading}>Find My Fit</h4>
                <p className={styles.subtext}>
                  Finding the perfect fit for you
                </p>
                {screenWidth <= TAB_WIDTH && (
                  <div
                    className={`col-lg-6 col-md-12 col-sm-12 ${styles.recommendationText}`}
                  >
                    <img
                      className={styles.introImg}
                      src={RECOMMENDATION}
                      alt=""
                    />
                  </div>
                )}
              </div>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  Provide personalized recommendations to increase user
                  engagement with your business.
                </li>
                <li className={styles.listItem}>
                  Enhance the user experience to increase the likelihood of a
                  repeat purchase or subscription.
                </li>
              </ul>
              <ExternalRedirect
                to={RECOMMENDATION_ENGINE_PRODUCTS_LIST}
                openLocation="newTab"
              >
                <div className={styles.btnWrapper}>
                  <CustomButton
                    text="Try Now"
                    customStyles={`${styles.btn} ${styles.uploadBtn}`}
                  />
                </div>
              </ExternalRedirect>
            </div>
            {screenWidth > TAB_WIDTH && (
              <div
                className={`col-lg-6 col-md-6 col-sm-12 ${styles.recommendationImage}`}
              >
                <img className={styles.introImg} src={RECOMMENDATION} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
      <HowItWorks
        heading={RECOMMENDATION_ENGINE_WORK?.howItWorks?.heading}
        subHeading={RECOMMENDATION_ENGINE_WORK?.howItWorks?.subHeading}
        list={RECOMMENDATION_ENGINE_WORK?.howItWorks?.list}
        displayImg={RECOMMENDATION_ENGINE_WORK?.howItWorks?.displayImg}
      />
    </>
  );
}
