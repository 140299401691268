import Player from '../Player/Player';
import CustomButton from '../Widgets/CustomButton/CustomButton';
import Loader from '../Loader/Loader';
import ErrorMsg from '../ErrorMsg/ErrorMsg';
import styles from './transcribed-video-style.module.css';

const TranscribedVideo = (props) => {
	const {
		uploadedVideo,
		setUploadedVideo,
		transcribedText,
		setTranscribedText,
		isLoading,
		isError,
	} = props;

	const transcribeNewVideoHandler = () => {
		setUploadedVideo('');
		setTranscribedText('');
	};

	const downloadTranscriptHandler = () => {
		const element = document.createElement('a');
		const file = new Blob([transcribedText], {
			type: 'text/plain',
		});
		element.href = URL.createObjectURL(file);
		element.download = `${uploadedVideo?.file_name.split('.')[0]}.txt`;
		document.body.appendChild(element);
		element.click();
		element.remove();
	};

	return (
		<div className={styles.container}>
			<h2 className={styles.heading}>Video Transcription</h2>
			<div className={styles.wrapper}>
				<div className={styles.videoWrapper}>
					<Player
						customStyles={styles.video}
						videoSrc={uploadedVideo?.link}
						controls
					/>
				</div>
				{isLoading && (
					<div className={styles.loaderWrapper}>
						<Loader loaderText='Transcribing' customStyles={styles.loaderStyle}/>
					</div>
				)}

				{isError && (
					<div className={styles.errorWrapper}>
						<ErrorMsg />
					</div>
				)}

				{transcribedText && (
					<div className={styles.transcriptContainer}>
						<h4 className={styles.transcriptHeading}>
							Transcript of the video
						</h4>
						<p className={styles.transcriptText}>{transcribedText}</p>

						<div className={styles.btnWrapper}>
							<CustomButton
								customStyles={styles.downloadBtn}
								text='Download Transcript'
								clickHandler={downloadTranscriptHandler}
							/>
							<CustomButton
								text='Transcript another video'
								customStyles={styles.newVideoBtn}
								clickHandler={transcribeNewVideoHandler}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TranscribedVideo;
