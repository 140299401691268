import { useReducer, createContext } from 'react';

import virtualTryOnReducer from './virtualTryOnReducer';

const initialState = {
	firstUpload: true,
	image: {
		modelId: '',
		original: '',
		file: '',
		id: '',
		isLoading: false,
		isError: false,
		status: '',
	},
	selectedProduct: {
		category: 'makeup',
		subCategory: 'lipstick',
		fileName: '',
		productId: '',
		colorCode: [],
	},
	clothImage: {
		uploadModal: false,
		file: '',
		id: '',
		isLoading: false,
	},
};

export const virtualTryOnContext = createContext(initialState);

const VirtualTryOnProvider = ({ children }) => {
	const [virtualTryOnState, virtualTryOnDispatch] = useReducer(
		virtualTryOnReducer,
		initialState
	);

	return (
		<virtualTryOnContext.Provider
			value={[virtualTryOnState, virtualTryOnDispatch]}
		>
			{children}
		</virtualTryOnContext.Provider>
	);
};

export default VirtualTryOnProvider;
