import { useContext } from "react";

import LEFT_ARROW_ICON from "../../../Assets/img/left_arrow.svg";

import Products from "../Products/Products";
import { selectCategory } from "../../../Context/VirtualTryOn/virtualTryOnActions";
import { virtualTryOnContext } from "../../../Context/VirtualTryOn/VirtualTryOnProvider";
import styles from "./Virtual_try_on_tabs-style.module.css";
import VirtualTryOnTabContainer from "../VirtualTryOnTabContainer/VirtualTryOnTabContainer";
import VirtualTryOnProducts from "../../VirtualTryOnProducts/VirtualTryOnProducts";

const tabList = [
  { id: 1, label: "Makeup", name: "makeup", subCategory: "lipstick" },
  { id: 2, label: "Jewellery", name: "jewelry", subCategory: "rings" },
  { id: 3, label: "Apparel", name: "apparel", subCategory: "clothes" },
];

const VirtualTryOnTabs = ({ screenWidth }) => {

  return (
    <>
      <VirtualTryOnTabContainer />
      <VirtualTryOnProducts screenWidth={screenWidth} />
    </>
  );
};

export default VirtualTryOnTabs;
