import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import useScreenWidth from "../../../Hooks/useScreenWidth";
import {
  MOBILE_WIDTH,
  RECOMMENDATION_BASIS,
} from "../../../Constants/constants.js";
import { styles } from "./ProductsListStyles.js";

export default function ProductsList(props) {
  const {
    title,
    products,
    titleStyle,
    rowGap,
    basis,
    isError = false,
    isLoading = false,
  } = props;
  const [screenWidth] = useScreenWidth();
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Grid sx={styles.productGridContainer}>
          {isError ? (
            <Box sx={styles.errorMsg}>Something went wrong</Box>
          ) : (
            <Grid
              container
              sx={styles.productContainer}
              columnGap={screenWidth <= MOBILE_WIDTH ? 6 : 10}
              rowGap={rowGap}
            >
              <Grid
                item
                sx={{ ...styles.headingText, ...titleStyle }}
                md={12}
                sm={12}
                xs={12}
              >
                {title}
              </Grid>
              {isLoading ? (
                <Box sx={styles.loaderContainer}>
                  <CircularProgress
                    color="inherit"
                    style={styles.loaderStyle}
                  />
                </Box>
              ) : (
                <>
                  {products?.map((product, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      md={6}
                      lg={3}
                      key={index}
                      sx={styles.product}
                    >
                      <Typography
                        component="img"
                        src={`${process.env.REACT_APP_RECOMMENDATION_IMAGE_BASE_URL}${product?.Image_path}`}
                        sx={styles.productImageStyle}
                        onClick={() =>
                          navigate(
                            `/recommendation-engine/product-description/${product?.article_id}`
                          )
                        }
                      ></Typography>
                      <Typography sx={styles.descriptionText}>
                        {product?.prod_name}{" "}
                      </Typography>
                      <Typography
                        sx={styles.descriptionText}
                      >{`Rs. ${product?.price}`}</Typography>
                      {basis === RECOMMENDATION_BASIS?.ALL_FEATURES && (
                        <Typography
                          sx={{
                            ...styles.descriptionText,
                            ...styles.scoreText,
                          }}
                        >{`${parseInt(
                          product?.score
                        )}% similar to above product`}</Typography>
                      )}
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
