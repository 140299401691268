import { useState } from "react";
import IMG_2 from "../../../Templates/Unthinkable/images/image 99.webp";
import "./navbar-styles.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <nav
        class={`navbar ${isOpen ? "navbar-dark" : "navbar-light"}`}
        id="test"
      >
        <div class="container">
          {" "}
          <div class="Logo">
            <div class="site-logo faux-heading">
              <a
                href="https://www.unthinkable.co/"
                class="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img src={IMG_2} alt="unthinkable" />
              </a>
              <span class="screen-reader-text">Unthinkable</span>
            </div>
          </div>{" "}
          <div
            class="navbar-toggler hemburger"
            onClick={() => setIsOpen(!isOpen)}
          >
            {" "}
            <span class="navbar-toggler-icon"></span>
          </div>
          <ul
            class={`nav-list hamburger-list ${
              isOpen ? "displayBlock" : "displayNone"
            }`}
          >
            <li
              id="menu-item"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item"
            >
              <a href="#!">
                <span>Horizontals</span>
              </a>
              <span class="icon"></span>{" "}
              <ul class="sub-menu">
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/design/">
                    <span>Design</span>
                  </a>
                </li>
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/architecture/">
                    <span>Architecture</span>
                  </a>
                </li>
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/technology/">
                    <span>Technology</span>
                  </a>
                </li>
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/analytics/">
                    <span>Analytics</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/security/">
                    <span>Security</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/intelligence/">
                    <span>Intelligence</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/cloud-services/">
                    <span>Cloud</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/devops-services/">
                    DevOps
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/marketing/">
                    <span>Marketing</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/expertise/data-enrichment-services/">
                    Data Enrichment
                  </a>
                </li>{" "}
              </ul>{" "}
            </li>{" "}
            <li
              id="menu-item"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item"
            >
              <a href="#!">
                <span>Verticals</span>
              </a>
              <span class="icon"></span>{" "}
              <ul class="sub-menu">
                {" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/healthcare/">
                    <span>Healthcare</span>
                  </a>
                </li>{" "}
                <li
                  id="menu-item"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
                >
                  <a href="https://www.unthinkable.co/digital-commerce/">
                    <span>Digital commerce</span>
                  </a>
                </li>{" "}
              </ul>{" "}
            </li>{" "}
            <li
              id="menu-item"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
            >
              <a href="https://www.unthinkable.co/about-us/">
                <span>About Us</span>
              </a>
            </li>{" "}
            <li
              id="menu-item"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
            >
              <a href="https://www.unthinkable.co/career/">
                <span>Career</span>
              </a>
            </li>{" "}
            <li
              id="menu-item"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item"
            >
              <a href="https://www.unthinkable.co/blog/">
                <span>Blogs</span>
              </a>
            </li>{" "}
            <li class="menus-last-link">
              {" "}
              <a
                href="#!"
                data-bs-toggle="modal"
                data-bs-target="#connectmodal"
                target="_self"
              >
                <div className="navbar-connect">Connect</div>
              </a>{" "}
            </li>{" "}
          </ul>{" "}
        </div>{" "}
      </nav>
    </>
  );
};

export default Navbar;
