import * as types from './types';
import { updateObject } from '../utils';

const setCategory = (state, action) => {
	return updateObject(state, action.payload);
};

const removeImage = (state, action) => {
	return updateObject(state, {
		image: action.payload,
		selectedProduct: { ...state.selectedProduct, productId: '', colorCode: [] },
	});
};

const uploadImage = (state, action) => {
	return updateObject(state, {
		...action.payload,
	});
};

const selectProduct = (state, action) => {
	return updateObject(state, {
		selectedProduct: {
			...state?.selectedProduct,
			...action.payload,
		},
	});
};

const toggleLoader = (state, action) => {
	return updateObject(state, {
		image: {
			...state?.image,
			...action.payload,
		},
	});
};

const processedImage = (state, action) => {
	return updateObject(state, {
		image: {
			...state?.image,
			...action.payload,
		},
	});
};

const processedImageError = (state, action) => {
	return updateObject(state, {
		image: {
			...state?.image,
			...action.payload,
		},
	});
};

const selectColorCode = (state, action) => {
	return updateObject(state, {
		selectedProduct: {
			...state.selectedProduct,
			...action.payload,
		},
	});
};

const processedImageStatus = (state, action) => {
	return updateObject(state, {
		image: {
			...state.image,
			...action.payload,
		},
	});
};

const selectModel = (state, action) => {
	return updateObject(state, {
		firstUpload: false,
		image: {
			...state.image,
			...action.payload.image,
		},
		selectedProduct: {
			...state.selectedProduct,
			...action.payload.selectedProduct,
		},
	});
};

const uploadClothImage = (state, action) => {
	return updateObject(state, {
		clothImage: {
			...state?.clothImage,
			...action.payload,
		},
	});
};

const uploadClothImageLoader = (state, action) => {
	return updateObject(state, {
		clothImage: {
			...state?.clothImage,
			...action.payload,
		},
	});
};

const uploadClothModal = (state, action) => {
	return updateObject(state, {
		clothImage: {
			...state?.clothImage,
			...action.payload,
		},
	});
};

const removeClothImage = (state, action) => {
	return updateObject(state, {
		...state,
		clothImage: {
			...state?.clothImage,
			...action.payload.clothImage,
		},
		image: {
			...state?.image,
			...action.payload.image,
		},
	});
};

const virtualTryOnReducer = (state, action) => {
	switch (action.type) {
		case types.VIRTUAL_TRY_ON_SELECT_CATEGORY:
			return setCategory(state, action);

		case types.VIRTUAL_TRY_ON_CLEAR_IMAGE:
			return removeImage(state, action);

		case types.VIRTUAL_TRY_ON_UPLOAD_IMAGE:
			return uploadImage(state, action);

		case types.VIRTUAL_TRY_ON_SELECT_PRODUCT:
			return selectProduct(state, action);

		case types.VIRTUAL_TRY_ON_TOGGLE_LOADER:
			return toggleLoader(state, action);

		case types.VIRTUAL_TRY_ON_PROCESSED_IMAGE:
			return processedImage(state, action);

		case types.VIRTUAL_TRY_ON_ERROR:
			return processedImageError(state, action);

		case types.VIRTUAL_TRY_ON_SELECT_COLOR:
			return selectColorCode(state, action);

		case types.VIRTUAL_TRY_ON_STATUS:
			return processedImageStatus(state, action);

		case types.VIRTUAL_TRY_ON_SELECT_MODEL:
			return selectModel(state, action);

		case types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE:
			return uploadClothImage(state, action);

		case types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_IMAGE_LOADER:
			return uploadClothImageLoader(state, action);

		case types.VIRTUAL_TRY_ON_UPLOAD_CLOTH_MODAL:
			return uploadClothModal(state, action);

		case types.VIRTUAL_TRY_ON_REMOVE_CLOTH_IMAGE:
			return removeClothImage(state, action);

		default:
			break;
	}
};

export default virtualTryOnReducer;
