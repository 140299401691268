import ChatBot from "../../Components/VoiceAssistant/ChatBot/ChatBot";
import HowItWorks from "../../Components/HowItWorks/HowItWorks";
import { VOICE_ASSISTANT } from "../../Constants/textContent";
import styles from "./voice_assistant-style.module.css";
import useScreenWidth from "../../Hooks/useScreenWidth";
import VoiceAssistantList from "./VoiceAssistantList/VoiceAssistantList";
import { MOBILE_WIDTH } from "../../Constants/constants";

const VoiceAssistant = () => {
  const [screenWidth] = useScreenWidth();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            <h3 className={styles.heading}>{VOICE_ASSISTANT?.heading}</h3>
            {screenWidth <= MOBILE_WIDTH && <ChatBot />}
            <VoiceAssistantList />
          </div>
          {screenWidth > MOBILE_WIDTH && (
            <div className={styles.mainWrapper}>
              <ChatBot />
            </div>
          )}
        </div>
      </div>
      <HowItWorks
        heading={VOICE_ASSISTANT?.howItWorks?.heading}
        subHeading={VOICE_ASSISTANT?.howItWorks?.subHeading}
        list={VOICE_ASSISTANT?.howItWorks?.list}
        displayImg={VOICE_ASSISTANT?.howItWorks?.displayImg}
      />
    </>
  );
};

export default VoiceAssistant;
