export const callApiUntilSuccessOrError = async (
	apiFunc,
	completedFunc,
	successFunc,
	errorFunc,
	apiPara,
	retries = 10,
	interval = 1000
) => {
	const attemptApiCall = async () => {
		try {
			const response = await apiFunc(apiPara);
			if (response?.status === 'completed') {
				completedFunc(response);
				return;
			} else if (response?.status === 'error' || retries === 0) {
				errorFunc(response);
				return;
			} else {
				retries -= 1;
				successFunc(response);
				await new Promise((resolve) => setTimeout(resolve, interval));
				return attemptApiCall();
			}
		} catch (error) {
			if (retries === 0) {
				throw error;
			} else {
				await new Promise((resolve) => setTimeout(resolve, interval));
				return attemptApiCall();
			}
		}
	};
	return attemptApiCall();
};
