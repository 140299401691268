import Backdrop from '../Backdrop/Backdrop';
import ERROR_IMG from '../../Assets/img/Error Placeholder.svg';
import ERROR_ICON from '../../Assets/img/Error Icon.svg';
import CANCEL_ICON from '../../Assets/img/Cancel Icon-2.svg';
import styles from './error_msg-style.module.css';

const ErrorMsg = (props) => {
	const {
		msg = 'Something went wrong! Please wait while we are working to fix this problem',
		closeHandler,
	} = props;
	return (
		<div className={styles.container}>
			{closeHandler && (
				<img
					className={styles.cancelIcon}
					onClick={closeHandler}
					src={CANCEL_ICON}
					alt=''
				/>
			)}
			<Backdrop customStyles={styles.overlay} />
			<div className={styles.wrapper}>
				<img
					className={styles.errorImg}
					src={ERROR_IMG}
					alt=''
				/>
				<div className={styles.textWrapper}>
					<img
						src={ERROR_ICON}
						alt=''
						className={styles.errorIcon}
					/>
					<p className={styles.errorText}>{msg}</p>
				</div>
			</div>
		</div>
	);
};

export default ErrorMsg;
