import { useContext, useEffect, useMemo, useRef, useState } from "react";

import HEADER_BANNER_IMG from "../../Assets/img/upload-top-banner.webp";
import CIRCLE_IMG from "../../Assets/img/Circles.svg";
import CIRCLE_IMG_1 from "../../Assets/img/Circles-1.svg";
import { VIRTUAL_TRY_ON } from "../../Constants/textContent";

import UploadModal from "../../Components/VirtualTryOn/UploadModal/UploadModal";
import Backdrop from "../../Components/Backdrop/Backdrop";
import ImageContainer from "../../Components/ImageContainer/ImageContainer";
import ExpertBanner from "../../Components/ExpertBanner/ExpertBanner";
import HowItWorks from "../../Components/HowItWorks/HowItWorks";
import VirtualTryOnTabs from "../../Components/VirtualTryOn/VirtualTryOnTabs/VirtualTryOnTabs";
import TryOnLoader from "../../Components/TryOnLoader/TryOnLoader";
import { virtualTryOnContext } from "../../Context/VirtualTryOn/VirtualTryOnProvider";
import styles from "./virtual_try_on_style.module.css";
import VirtualTryOnTabContainer from "../../Components/VirtualTryOn/VirtualTryOnTabContainer/VirtualTryOnTabContainer";
import VirtualTryOnProducts from "../../Components/VirtualTryOnProducts/VirtualTryOnProducts";
import useScreenWidth from "../../Hooks/useScreenWidth";
import { TAB_WIDTH } from "../../Constants/constants";
import useIsInViewport from "../../Hooks/useIsInViewport";

export default function VirtualTryOn() {
  const [state] = useContext(virtualTryOnContext);
  const [screenWidth] = useScreenWidth();

  const ref = useRef();
  const isVisible = useIsInViewport(ref);

  return (
    <div className={styles.container}>
      {state?.clothImage?.uploadModal && (
        <UploadModal
          headerLabel={VIRTUAL_TRY_ON?.apparel?.clothUploadModal?.headerLabel}
          textList={VIRTUAL_TRY_ON?.apparel?.clothUploadModal?.textList}
          img={VIRTUAL_TRY_ON?.apparel?.clothUploadModal?.sideImg}
        />
      )}

      <div className={styles.headerWrapper}>
        <Backdrop customStyles={styles.headerBackdrop} />
        <img
          className={styles.headerBackground}
          src={HEADER_BANNER_IMG}
          alt=""
        />
        <p className={styles.headerText}>Virtual Try On</p>
      </div>
      <div className={styles.mainContent}>
        {/* <img
					className={styles.circleBackdropImg}
					src={CIRCLE_IMG}
					alt=''
				/>
				<img
					className={styles.circleBackdropImg1}
					src={CIRCLE_IMG_1}
					alt=''
				/> */}
        <div className={styles.imageContainer}>
          {screenWidth <= TAB_WIDTH && <VirtualTryOnTabContainer />}
          {state?.image?.isLoading && (
            <div className={styles.imageContainerOverlay}>
              <div className={styles.imageContainerLoader}>
                <TryOnLoader value={state?.image?.status}></TryOnLoader>
              </div>
            </div>
          )}
          <ImageContainer ref={ref} />
          {screenWidth <= TAB_WIDTH && (
            <VirtualTryOnProducts
              screenWidth={screenWidth}
              isVisible={isVisible}
            />
          )}
        </div>
        {screenWidth > TAB_WIDTH && (
          <div className={styles.productContainer}>
            <VirtualTryOnTabs screenWidth={screenWidth} />
          </div>
        )}
      </div>
      <HowItWorks
        heading={VIRTUAL_TRY_ON?.howItWorks?.heading}
        subHeading={VIRTUAL_TRY_ON?.howItWorks?.subHeading}
        list={VIRTUAL_TRY_ON?.howItWorks?.list}
        displayImg={VIRTUAL_TRY_ON?.howItWorks?.displayImg}
      />
      {/* <ExpertBanner /> */}
    </div>
  );
}
