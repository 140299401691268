import { useRef } from 'react';
import styles from './custom_button-styles.module.css';

const CustomButton = (props) => {
	const { text, clickHandler, fileUpload, accept, startIcon, customStyles } =
		props;

	const fileInputRef = useRef(null);

	const handleClick = () => {
		if (fileUpload) {
			fileInputRef.current.click();
		} else {
			clickHandler();
		}
	};

	const fileChangeHandler = (e) => {
		const file = e.target.files[0];
		clickHandler(file);
	};

	return (
		<div
			className={`${styles.btn} ${customStyles}`}
			onClick={handleClick}
		>
			{startIcon && (
				<img
					className={styles.m}
					src={startIcon}
					alt=''
				/>
			)}
			{text}
			{fileUpload && (
				<input
					type='file'
					accept={accept || '*'}
					ref={fileInputRef}
					onChange={fileChangeHandler}
					className={styles.fileInput}
				/>
			)}
		</div>
	);
};

export default CustomButton;
