import LipstickImage1 from "../../src/Assets/img/image 24.webp";
import LipstickImage2 from "../../src/Assets/img/image 22.webp";
import LipstickImage3 from "../../src/Assets/img/image-58lipTint.webp";

import frame66 from "../../src/Assets/img/Frame_66.webp";
import frame67 from "../../src/Assets/img/Frame_67.webp";

import footer1 from "../../src/Assets/img/footer1.webp";
import footer2 from "../../src/Assets/img/footer2.webp";
import footer3 from "../../src/Assets/img/footer3.webp";
import footer4 from "../../src/Assets/img/footer4.webp";
import footer5 from "../../src/Assets/img/footer5.webp";
import footer6 from "../../src/Assets/img/footer6.webp";
import footer7 from "../../src/Assets/img/footer7.webp";

import SUCCESS_STORY_IMG_1 from "../Assets/img/success-story-1.webp";
import SUCCESS_STORY_IMG_2 from "../Assets/img/success-story-2.webp";
import SUCCESS_STORY_IMG_3 from "../Assets/img/success-story-3.webp";

import NO_SHADE from "../Assets/img/noShade.png";

export const items1 = ["Makeup", "Jewellery"];
export const JewellerySubHeader = ["Earings", "Rings"];

export const makeUpSubHeader = ["Lipstick"];
export const items4 = [
  "Earings",
  "Rings",
  "Necklace",
  "Pandents",
  "Bangles",
  "Earings",
  "Necklace",
  "Pandents",
  "Bangles",
];

export const productArray = [
  {
    img: frame66,
    p: "Velvet Smooth",
    br: " Non Transfer Lipstick",
  },
  {
    img: frame67,
    p: " Smudge Proof ",
    br: "  Smooth Liquid Lipstick",
  },
];
export const LipstickItems = [
  {
    id: 1,
    img: LipstickImage1,
    br: "Velvet Smooth",
    p: "Non Transfer Lipstick",
  },
  {
    id: 2,
    img: LipstickImage2,
    br: " Smudge Proof ",
    p: "Smooth Liquid Lipstick",
  },
  {
    id: 3,
    img: LipstickImage3,
    br: "Lip Tint and Balm",
    p: " ",
  },
];

export const RingsItems = [
  { br: "Claire Cluster", p: "Diamond Ring" },
  { br: "Inaya Solitaire Ring", p: "" },
  { br: "Glee Princess", p: "Solitaire Ring" },
];
export const ClothItems = [
  { br: "Pink Black-Striped", p: "Half-Sleeve Top" },
  { br: "Dark-Blue", p: "Full-Sleeve Collar T-shirt" },
];
export const colorCodeData = [
  {
    id: 1,
    color: [],
    style: {
      backgroundColor: "#d7d7d7",
      backgroundImage: `url(${NO_SHADE})`,
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  },
  {
    id: 2,
    color: [157, 29, 53],
    style: { backgroundColor: "rgb(157,29,52)" },
    shade: "Deep Rose"
  },
  {
    id: 3,
    color: [148, 44, 95],
    style: { backgroundColor: "rgb(148,44,95)" },
    shade: "Burgundy Wine"
  },
  {
    id: 4,
    color: [116, 61, 64],
    style: { backgroundColor: "rgb(116,61,64)" },
    shade: "Hot Shot Red"
  },
];

export const awardData = [
  {
    id: 1,
    img: footer1,
  },
  {
    id: 2,
    img: footer2,
  },
  {
    id: 3,
    img: footer3,
  },
  {
    id: 4,
    img: footer4,
  },
  {
    id: 5,
    img: footer5,
  },
  { id: 6, img: footer7 },
  { id: 7, img: footer6 },
];

export const successStoryData = [
  {
    id: 1,
    img: SUCCESS_STORY_IMG_1,
    description:
      "Developing an AI-based image editing solution for one of India’s largest online jewelry marketplaces- eJohri",
    link: "https://www.daffodilsw.com/case-study/developing-an-ai-based-image-editing-solution/",
  },
  {
    id: 2,
    img: SUCCESS_STORY_IMG_2,
    description:
      "Building an Automatic Speech Recognition Engine for AI-enabled Legal Tech Software Firm",
    link: "https://www.daffodilsw.com/case-study/ai-legal-software-development/",
  },
  {
    id: 3,
    img: SUCCESS_STORY_IMG_3,
    description:
      "Daffodil develops an AI-powered license plate recognition app for efficient parking lot management",
    link: "https://www.daffodilsw.com/case-study/smart-parking-management-system/",
  },
];
