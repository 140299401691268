import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import DefaultRoute from "./Routes/DefaultRoute";
import PublicRoute from "./Routes/PublicRoute";
import Layout from "./Components/Layout/Layout";
import RecommendationLayout from "./Components/Recommendation/RecommendationLayout/RecommendationLayout";
import { publicRoutes } from "./Routes/PublicRoutes";
import { WILDCARD } from "./Routes/Routes";
import { GlobalProvider } from "./Context/GlobalProvider";
import "./App1.css";

function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Routes>
          {publicRoutes.map((routeDetail) => {
            return (
              <Route
                element={
                  routeDetail?.isLayoutNeeded ? (
                    <Layout>
                      <Outlet />
                    </Layout>
                  ) : (
                    <RecommendationLayout>
                      <Outlet />
                    </RecommendationLayout>
                  )
                }
                key={routeDetail?.id}
              >
                <Route exact path="/" element={<PublicRoute />}>
                  <Route
                    exact
                    path={routeDetail?.path}
                    element={routeDetail?.component}
                  />
                </Route>
              </Route>
            );
          })}
          <Route path={WILDCARD} element={<DefaultRoute />} />
        </Routes>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;
