import { axiosInstance } from '../API/axiosInstance';
import { VOICE_ASSISTANT_API } from '../Constants/apiEndpoint';

export const textSendService = async (txt) => {
	const response = { error: '', data: null };
	try {
		const data = { text: txt };
		response.data = await axiosInstance.post(VOICE_ASSISTANT_API, data);
	} catch (err) {
		if (err?.code === 'ERR_NETWORK') {
			response.error = err?.message;
		} else {
			response.error = err?.response;
		}
	}
	return response;
};
