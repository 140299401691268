import { useEffect, useState } from 'react';

import ErrorIcon from '../../../Assets/img/Error Icon.svg';
import MsgLoadingGif from '../../../Assets/img/Animation.gif';
import chatLoader from '../../../Assets/img/chatLoader3.gif'
import ChatOptions from '../ChatOption/ChatOptions.jsx';
import styles from './chat_message-style.module.css';

export default function ChatMessage(props) {
	const { message, sendMessage, setMessage, index, fullMsg } = props;

	const [isMsgVisible, setIsMsgVisible] = useState(false);

	const divWidthHandler = () => {
		setIsMsgVisible(false);
		if (!message?.isError) {
			var d = document.querySelectorAll('.msg'),
				i,
				w,
				width,
				height;
			for (i = 0; i < d.length; i++) {
				width = d[i].offsetWidth;
				height = d[i].offsetHeight;
				for (w = width; w; w--) {
					d[i].style.width = w + 'px';
					if (d[i].offsetHeight !== height) break;
				}
				if (w < d[i].scrollWidth) {
					d[i].style.width = d[i].style.maxWidth = d[i].scrollWidth + 'px';
				} else {
					d[i].style.width = w + 1 + 'px';
				}
			}
		}
		setIsMsgVisible(true);
	};

	useEffect(() => {
		divWidthHandler();
	}, [message]);

	return (
		<div className={styles.container}>
			{message?.isLoading ? (
				<div className={`${styles.msgAlt} ${styles.msgLeftSide}`}>
					<img
						src={chatLoader}
						alt=''
						className={styles.msgLoader}
					/>
				</div>
			) : (
				<>
					<div
						style={
							isMsgVisible
								? { visibility: 'visible' }
								: { visibility: 'hidden' }
						}
						className={`msg ${styles.msg} ${
							message?.send ? styles.msgRightSide : styles.msgLeftSide
						} ${message?.isError && styles.msgError}`}
					>
						{message?.isError && (
							<img
								alt=''
								src={ErrorIcon}
								className={styles.errorIcon}
							/>
						)}
						{message?.text}
					</div>
					{message?.isoptions && (
						<ChatOptions
							index={index}
							message={message}
							fullMsg={fullMsg}
							setMessage={setMessage}
							sendMessage={sendMessage}
						/>
					)}
				</>
			)}
		</div>
	);
}
