import styles from './player-styles.module.css';

const Player = (props) => {
	const { videoSrc, controls, thumbnail, customStyles } = props;

	return (
		<video
			className={`${styles.player} ${customStyles}`}
			controls={controls}
			poster={thumbnail}
		>
			<source src={videoSrc} />
		</video>
	);
};

export default Player;
