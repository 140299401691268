import { useState } from 'react';

import styles from './chat_option-style.module.css';

const ChatOptions = (props) => {
	const { message, sendMessage, setMessage } = props;

	const [msg, setMsg] = useState(message);

	const chooseOptionHandler = ({ msgId, optionId }) => {
		const newOptions = message?.options?.map((item) => {
			if (item?.id === optionId) {
				return { ...item, isSelected: true };
			}
			return { ...item, isDisabled: true };
		});
		const newMessage = { ...message, options: newOptions, isActive: false };
		setMsg(newMessage);
		setMessage((prev) => {
			return prev?.map((item) => {
				if (item?.id === msgId) {
					console.log('first', item, newMessage);
					return newMessage;
				}
				return item;
			});
		});
		sendMessage(`I want to choose option ${optionId + 1}`);
	};

	return msg?.options?.map((optionObj, index) => {
		return (
			<div
				key={index}
				className={`${styles.container} ${
					optionObj?.isDisabled && styles.disableOption
				} ${optionObj?.isSelected && styles.selectedOption}`}
				onClick={() => {
					if (msg?.isActive) {
						return chooseOptionHandler({
							msgId: message?.id,
							optionId: optionObj?.id,
						});
					}
				}}
			>
				{optionObj?.text}
			</div>
		);
	});
};

export default ChatOptions;
