import React,{ useRef, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';

import APPAREL_ICON from '../../Assets/img/ModelOutline.svg';
import MODEL_ICON from '../../Assets/img/choode_model_icon.svg';
import DOWNLOAD_ICON from '../../Assets/img/DownloadOutline.svg';
import CAMERA_ICON from '../../Assets/img/CameraOutline.svg';
import CANCEL_ICON from '../../Assets/img/Cancel Icon.svg';
import CAPTURE_ICON from '../../Assets/img/Capture_Icon.svg';
import CAMERA_BTN_ICON from '../../Assets/img/cameraBtn.svg';
import { VIRTUAL_TRY_ON } from '../../Constants/textContent';
import {
	SOMETHING_WENT_WRONG,
	SOMETHING_WENT_WRONG_TRY_FIX,
} from '../../Constants/errorMessage';
import { APPAREL_TRY_ON_STATIC_FILE } from '../../Constants/apiEndpoint';

import imageDownload from '../../Utils/imageDownload';
import UploadPhoto from '../UploadPhoto/UploadPhoto';
import Loader from '../Loader/Loader';
import ErrorMsg from '../ErrorMsg/ErrorMsg';
import ModelsSlider from '../VirtualTryOn/ModelsSlider/ModelsSlider';
import Backdrop from '../Backdrop/Backdrop';
import {
	clearImage,
	selectProduct,
	uploadImage,
	processedImageError,
} from '../../Context/VirtualTryOn/virtualTryOnActions';
import {
	uploadModelImageService,
	uploadModelStatusService,
	uploadPhotoFileService,
} from '../../Services/cosmeticTryOnService';
import { virtualTryOnContext } from '../../Context/VirtualTryOn/VirtualTryOnProvider';
import './ImageContainerStyles.css';
import { callApiUntilSuccessOrError } from '../../Utils/helperFunctions';

const ImageContainer = React.forwardRef((props,ref) => {
	const [state, dispatch] = useContext(virtualTryOnContext);

	const webcamRef = useRef();

	const [showCameraFeed, setShowCameraFeed] = useState(false);
	const [showModels, setShowModels] = useState(false);
	const [imgFile, setImgFile] = useState(state?.image?.file);
	const [load, setLoad] = useState(true);
	const [fileUpload, setFileUpload] = useState(false);

	useEffect(() => {
		setLoad(true);
		setImgFile(state?.image?.file);
	}, [state?.image?.file]);

	useEffect(() => {
		setShowModels(false);
		if(state?.selectedProduct?.category==='apparel')
		{
			setShowCameraFeed(false)
		}
	}, [state?.selectedProduct?.category]);



	const captureImgHandler = async () => {
		const imageSrc = webcamRef.current.getScreenshot();
		const blob = await fetch(imageSrc).then((res) => res.blob());
		setFileUpload(true);
		const response = await uploadPhotoFileService(blob);
		if (response?.data) {
			dispatch(uploadImage(response?.data?.data?.uploaded_file, imageSrc));
			if (state?.selectedProduct?.subCategory === 'lipstick') {
				dispatch(selectProduct(1, ''));
			}
		} else {
			toast.error(SOMETHING_WENT_WRONG);
		}
		setFileUpload(false);
	};

	const imageDownloadHandler = () => {
		imageDownload({ imgUrl: imgFile });
	};

	const showModelHandler = () => {
		setShowModels(true);
	};

	const showCameraHandler = () => {
		setShowCameraFeed(true);
		dispatch(clearImage());
	};

	const cancelBtnHandler = () => {
		setShowCameraFeed(false);
		dispatch(clearImage());
	};

	const fileUploadHandler = async (file) => {
		setFileUpload(true);
		if (state?.selectedProduct?.category === 'apparel') {
			const response = await uploadModelImageService(file);
			if (response?.data) {
				callApiUntilSuccessOrError(
					ModalUploadStatus,
					ModalUploadCompletedFunc,
					ModalUploadSuccessFunc,
					ModalUploadErrorFunc,
					{ task_id: response?.data?.data?.task_id },
					50
				);
			} else {
				dispatch(processedImageError(SOMETHING_WENT_WRONG_TRY_FIX));
				setFileUpload(false);
			}
		} else {
			const response = await uploadPhotoFileService(file);
			if (response?.data) {
				dispatch(
					uploadImage(
						response?.data?.data?.uploaded_file,
						URL.createObjectURL(file)
					)
				);
				if (state?.selectedProduct?.subCategory === 'lipstick') {
					dispatch(selectProduct(1, ''));
				}
			} else {
				dispatch(processedImageError(SOMETHING_WENT_WRONG_TRY_FIX));
			}
			setFileUpload(false);
		}
	};

	const ModalUploadStatus = async ({ task_id }) => {
		const response = await uploadModelStatusService(task_id);
		if (response?.data?.data?.percentage === '100') {
			return {
				status: 'completed',
				uploadedFile: response?.data?.data?.model,
				filePath: response?.data?.data?.file_path,
			};
		} else if (
			response?.data?.data?.status === 'error' ||
			response?.error?.status === 500
		) {
			return {
				status: 'error',
				msg: SOMETHING_WENT_WRONG_TRY_FIX,
			};
		} else if (
			response?.error?.status === 400 ||
			response?.error?.status === 404
		) {
			return {
				status: 'error',
				msg: response?.error?.data?.Error || SOMETHING_WENT_WRONG_TRY_FIX,
			};
		} else {
			return {
				status: 'success',
				percentage: response?.data?.data?.percentage,
			};
		}
	};

	const ModalUploadCompletedFunc = ({ uploadedFile, filePath }) => {
		setFileUpload(false);
		dispatch(
			uploadImage(uploadedFile, `${APPAREL_TRY_ON_STATIC_FILE}${filePath}`)
		);
	};

	const ModalUploadSuccessFunc = ({ percentage }) => {};

	const ModalUploadErrorFunc = ({ msg }) => {
		setFileUpload(false);
		dispatch(processedImageError(msg));
	};

	const overlayInfo = () => {
		switch (state?.selectedProduct?.subCategory) {
			case 'lipstick':
				return VIRTUAL_TRY_ON?.overlay?.lipstick;

			case 'rings':
				return VIRTUAL_TRY_ON?.overlay?.rings;

			case 'earings':
				return VIRTUAL_TRY_ON?.overlay?.rings;

			case 'clothes':
				return VIRTUAL_TRY_ON?.overlay?.clothes;

			default:
				break;
		}
	};

	const showContent = () => {
		if (state?.image?.errorMsg) {
			return (
				<ErrorMsg
					msg={state?.image?.errorMsg}
					closeHandler={cancelBtnHandler}
				/>
			);
		} else if (fileUpload) {
			return (
				<div>
					<Loader loaderText='Uploading' />
				</div>
			);
		} else if (showCameraFeed && !imgFile ) {
			return (
				<>


					<Webcam
						audio={false}
						height={100 + '%'}
						ref={webcamRef}
						screenshotFormat='image/jpeg'
						width={100 + '%'}
					/>
					<div className='close-wrapper'>
						<img
							onClick={cancelBtnHandler}
							src={CANCEL_ICON}
							alt=''
						/>
					</div>
					<img
						className='icon'
						src={CAPTURE_ICON}
						alt=''
						onClick={captureImgHandler}
					/>
				</>
			);
		} else if (imgFile) {
			return (
				<div className='uploaded-img'>
					<img
						src={imgFile}
						alt=''
						onLoad={() => setLoad(false)}
					/>
					{showModels && (
						<>
							<Backdrop clickHandler={() => setShowModels(false)} />
							<ModelsSlider setShowModels={setShowModels} />
						</>
					)}
				</div>
			);
		} else {
			return (
				<div className='choose-screen'>
					<img
						src={overlayInfo()?.img}
						alt=''
					/>
					<ul className='text'>
						{overlayInfo()?.text?.map((item, idx) => {
							return <li key={idx}>{item}</li>;
						})}
					</ul>

					<div className='btn-wrapper'>
						<button
							className='capture-btn'
							onClick={
								state?.selectedProduct?.subCategory === 'clothes'
									? showModelHandler
									: showCameraHandler
							}
						>
							<img
								src={
									state?.selectedProduct?.subCategory === 'clothes'
										? MODEL_ICON
										: CAMERA_BTN_ICON
								}
								alt=''
							/>
							{state?.selectedProduct?.subCategory === 'clothes'
								? 'Choose Model'
								: 'Capture Image'}
						</button>
						<UploadPhoto handleFile={fileUploadHandler} />
					</div>
					{showModels && (
						<>
							<Backdrop clickHandler={() => setShowModels(false)} />
							<ModelsSlider setShowModels={setShowModels} />
						</>
					)}
				</div>
			);
		}
	};

	return (
		<div className='main-container' ref={ref}>
			<div className='loader-wrapper'>
				{load && imgFile && <Loader loaderText='Loading' />}
			</div>
			<div className='option-wrapper'>
				{imgFile && !state?.image?.isLoading && !state?.image?.errorMsg && (
					<>
						<img
							className='icon-btn'
							src={DOWNLOAD_ICON}
							alt=''
							onClick={imageDownloadHandler}
						/>
						{state?.selectedProduct?.category === 'apparel' ? (
							<img
								className='icon-btn'
								src={APPAREL_ICON}
								alt=''
								onClick={() => setShowModels(true)}
							/>
						) : (
							<img
								className='icon-btn'
								src={CAMERA_ICON}
								alt=''
								onClick={showCameraHandler}
							/>
						)}
					</>
				)}
			</div>
			{imgFile && !state?.image?.isLoading && !state?.image?.errorMsg && (
				<div className='close-wrapper'>
					<img
						onClick={cancelBtnHandler}
						src={CANCEL_ICON}
						alt=''
						className='cancel-icon'
					/>
				</div>
			)}
			{showContent()}
		</div>
	);
});

export default ImageContainer;
