import { toast } from 'react-toastify';

import UPLOAD_ICON from '../../../Assets/img/Upload Icon-2.svg';
import CANCEL_ICON from '../../../Assets/img/Cancel Icon-2.svg';
import {
	SOMETHING_WENT_WRONG,
	SOMETHING_WENT_WRONG_TRY_FIX,
} from '../../../Constants/errorMessage';
import { APPAREL_TRY_ON_STATIC_FILE } from '../../../Constants/apiEndpoint';

import Loader from '../../Loader/Loader';
import CustomButton from '../../Widgets/CustomButton/CustomButton';
import Backdrop from '../../Backdrop/Backdrop';
import {
	uploadClothesImageService,
	uploadClothesStatusService,
} from '../../../Services/cosmeticTryOnService';
import { useContext } from 'react';
import { virtualTryOnContext } from '../../../Context/VirtualTryOn/VirtualTryOnProvider';
import {
	uploadClothImage,
	uploadClothImageLoader,
	uploadClothModal,
} from '../../../Context/VirtualTryOn/virtualTryOnActions';
import styles from './upload_modal_style.module.css';
import { callApiUntilSuccessOrError } from '../../../Utils/helperFunctions';

const UploadModal = (props) => {
	const { headerLabel, img, textList } = props;

	const [state, dispatch] = useContext(virtualTryOnContext);

	const fileUploadHandler = async (file) => {
		dispatch(uploadClothImageLoader(true));
		const response = await uploadClothesImageService(file);
		if (response?.data) {
			callApiUntilSuccessOrError(
				clothUploadStatus,
				clothUploadCompletedFunc,
				clothUploadSuccessFunc,
				clothUploadErrorFunc,
				{ task_id: response?.data?.data?.task_id },
				50
			);
		} else {
			dispatch(uploadClothImageLoader(false));
			toast.error(SOMETHING_WENT_WRONG);
		}
	};

	const clothUploadStatus = async ({ task_id }) => {
		const response = await uploadClothesStatusService(task_id);
		if (response?.data?.data?.percentage === '100') {
			return {
				status: 'completed',
				uploadedFile: response?.data?.data?.cloth,
				filePath: response?.data?.data?.file_path,
			};
		} else if (
			response?.data?.data?.status === 'error' ||
			response?.error?.status === 500
		) {
			return {
				status: 'error',
				msg: SOMETHING_WENT_WRONG_TRY_FIX,
			};
		} else if (
			response?.error?.status === 400 ||
			response?.error?.status === 404
		) {
			return { status: 'error', msg: response?.error?.data?.Error };
		} else {
			return {
				status: 'success',
				percentage: response?.data?.data?.percentage,
			};
		}
	};

	const clothUploadCompletedFunc = ({ uploadedFile, filePath }) => {
		dispatch(
			uploadClothImage(uploadedFile, `${APPAREL_TRY_ON_STATIC_FILE}${filePath}`)
		);
	};

	const clothUploadSuccessFunc = ({ percentage }) => {};

	const clothUploadErrorFunc = () => {
		dispatch(uploadClothImageLoader(false));
		toast.error(SOMETHING_WENT_WRONG);
	};

	const closeModalHandler = () => {
		dispatch(uploadClothModal(false));
	};

	return (
		<div className={styles.container}>
			<Backdrop />
			<div className={styles.modal}>
				<div className={styles.header}>
					<img
						className={styles.cancelIcon}
						src={CANCEL_ICON}
						alt=''
						onClick={closeModalHandler}
					/>
					{headerLabel}
				</div>
				<div className={styles.line}></div>
				<div className={styles.mainContent}>
					<ul className={styles.textContent}>
						{textList?.map((item, idx) => {
							return (
								<li
									className={styles.textContentItem}
									key={idx}
								>
									{item}
								</li>
							);
						})}
					</ul>
					<img
						className={styles.imgContent}
						src={img}
						alt=''
					/>
				</div>
				<CustomButton
					fileUpload
					text='Upload'
					startIcon={UPLOAD_ICON}
					customStyles={styles.btn}
					clickHandler={fileUploadHandler}
				/>

				{state?.clothImage?.isLoading && (
					<div className={styles.loaderWrapper}>
						<Backdrop />
						<Loader
							type='secondary'
							loaderText='Uploading'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default UploadModal;
