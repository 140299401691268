import * as _ from 'lodash';
import * as crypto from 'crypto-js';

const getDecryptedVal = (val, secretKey) => {
	const decryptObj = crypto.AES.decrypt(val, secretKey);
	return _.attempt(_.invoke, decryptObj, 'toString', crypto.enc.Utf8);
};

export const setItem = (key, val) => {
	let parsedVal;

	if (_.isObject(val) || _.isArray(val)) {
		parsedVal = _.attempt(JSON.stringify.bind(null, val));
	} else if (_.isString(val)) {
		parsedVal = val;
	}

	//   encrypt key and store in sessionStorage
	if (!_.isError(parsedVal) && !_.isUndefined(parsedVal)) {
		parsedVal = crypto.AES.encrypt(parsedVal, key);
		sessionStorage.setItem(key, parsedVal);
	}
};

export const getItem = (key) => {
	const retrievedVal = sessionStorage.getItem(key);
	if (_.isNull(retrievedVal)) {
		return null;
	}
	const decryptedVal = getDecryptedVal(retrievedVal, key);
	//   value modified by user
	if (_.isError(decryptedVal) || _.isEmpty(decryptedVal)) {
		this.removeItem(key);
		return null;
	}
	const decryptedObj = _.attempt(JSON.parse.bind(null, decryptedVal));
	return _.isError(decryptedObj) ? decryptedVal : decryptedObj;
};

export const removeItem = (key) => {
	sessionStorage.removeItem(key);
};
