import { useEffect, useState, useContext, useRef } from "react";

import { STATIC_FILE } from "../../../Constants/apiEndpoint";
import { colorCodeData } from "../../../Data/data";

import {
  sendColorCodeService,
  getTryOnFileService,
} from "../../../Services/cosmeticTryOnService";
import { selectColorCode } from "../../../Context/VirtualTryOn/virtualTryOnActions";
import { virtualTryOnContext } from "../../../Context/VirtualTryOn/VirtualTryOnProvider";
import {
  processedImage,
  processedImageError,
  processedImageStatus,
  toggleLoader,
} from "../../../Context/VirtualTryOn/virtualTryOnActions";
import "./ShadesCardStyle.css";
import { SOMETHING_WENT_WRONG_TRY_FIX } from "../../../Constants/errorMessage";
import { callApiUntilSuccessOrError } from "../../../Utils/helperFunctions";
import useIsInViewport from "../../../Hooks/useIsInViewport";

const ShadesCard = ({ isVisible }) => {
  const [state, dispatch] = useContext(virtualTryOnContext);

  const [shade, setShade] = useState();

  const selectShadeHandler = (data) => {
    if (data?.color) {
      dispatch(selectColorCode(data?.color));
      handleTryOn(data?.color);
    }
  };

  const handleTryOn = async (colorCode) => {
    dispatch(toggleLoader(true));
    if (colorCode.length) {
      const response = await sendColorCodeService(colorCode, state?.image?.id);
      callApiUntilSuccessOrError(
        TryOnStatus,
        TryOnCompletedFunc,
        TryOnSuccessFunc,
        TryOnErrorFunc,
        { task_id: response?.data?.data?.task_id },
        50
      );
    } else {
      dispatch(processedImage(state?.image?.original));
    }
  };

  const TryOnStatus = async ({ task_id, type }) => {
    const response = await getTryOnFileService(task_id);
    if (response?.data?.data?.percentage === "100") {
      return { status: "completed", filePath: response?.data?.data?.file_path };
    } else if (
      response?.data?.data?.status === "error" ||
      response?.error?.status === 500
    ) {
      return {
        status: "error",
        msg: SOMETHING_WENT_WRONG_TRY_FIX,
      };
    } else if (
      response?.error?.status === 400 ||
      response?.error?.status === 404
    ) {
      return { status: "error", msg: response?.error?.data?.Error };
    } else {
      return {
        status: "success",
        percentage: response?.data?.data?.percentage,
      };
    }
  };

  const TryOnCompletedFunc = ({ filePath }) => {
    dispatch(processedImage(`${STATIC_FILE}${filePath}`));
  };

  const TryOnSuccessFunc = ({ percentage }) => {
    dispatch(processedImageStatus(percentage));
  };

  const TryOnErrorFunc = ({ msg }) => {
    dispatch(processedImageError(msg));
  };

  useEffect(() => {
    const colorStyle = colorCodeData?.filter(
      (item) => item?.color === state?.selectedProduct?.colorCode
    )[0];
    setShade(colorStyle || colorCodeData[0]);
  }, [state?.selectedProduct?.colorCode]);

  return (
    <div className="shadeContainer">
      <div
        className={`main-wrapper-shades ${
          !isVisible && "move-shade-container"
        }`}
      >
        <div className="text-wrapper">
          <p>
            {!state?.selectedProduct?.colorCode?.length
              ? `*Select a Shade*`
              : shade?.shade}
          </p>
          <p>Shades ({colorCodeData?.length - 1 || 0})</p>
        </div>

        <div className="color-wrapper">
          <div className="selected-shade" style={shade?.style}></div>
          <div className="shade-container">
            {colorCodeData?.map((item) => {
              return (
                <div
                  onClick={() => selectShadeHandler(item)}
                  key={item?.id}
                  className={`shade ${
                    JSON.stringify(item?.color) ===
                      JSON.stringify(state?.selectedProduct?.colorCode) &&
                    "shade-active"
                  }`}
                  style={item?.style}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadesCard;
