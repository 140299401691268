import LOGO from "../../../Templates/Unthinkable/images/logo.webp";
import LINKDIN from "../../../Templates/Unthinkable/images/linkedin.webp";
import INSTAGRAM from "../../../Templates/Unthinkable/images/instagram.webp";
import YOUTUBE from "../../../Templates/Unthinkable/images/youtube.webp";
import TWITTER from "../../../Templates/Unthinkable/images/twitter.webp";

import "./footer-styles.css";
import ExternalRedirect from "../../ExternalRedirect/ExternalRedirect";
import useScreenWidth from "../../../Hooks/useScreenWidth";
import FooterIcons from "./FooterIcons/FooterIcons";
import { MOBILE_WIDTH } from "../../../Constants/constants";

const Footer = () => {
  const [screenWidth] = useScreenWidth();
  return (
    <section>
      <div className="container">
        <div className="row footer-row">
          <div className="col-lg-4 col-md-12 col-sm-4 footer">
            <div className="logo">
              <img src={LOGO} alt="" />
            </div>
            <p> Challenging the software engineering norms</p>
            <ExternalRedirect
              to="https://www.unthinkable.co/intelligence/"
              openLocation="newTab"
            >
              <div className="footer-connect">Connect</div>
            </ExternalRedirect>
            {screenWidth > MOBILE_WIDTH && <FooterIcons />}
          </div>
          <div className="col-lg-2 col-6 col-md-3 col-sm-2">
            <ul>
              <span> Who we are</span>
              <li>
                <a href=" https://www.unthinkable.co/about-us/" target="_blank">
                  {" "}
                  About us
                </a>
              </li>
              <li>
                <a href=" https://www.unthinkable.co/blog/" target="_blank">
                  {" "}
                  Blogs
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 col-md-3 col-sm-2 Horizontals">
            <ul>
              <span>Horizontals</span>
              <li>
                <a href="https://www.unthinkable.co/design/" target="_blank">
                  {" "}
                  Design
                </a>
              </li>
              <li>
                <a
                  href=" https://www.unthinkable.co/architecture/"
                  target="_blank"
                >
                  {" "}
                  Architecture
                </a>
              </li>
              <li>
                <a
                  href=" https://www.unthinkable.co/technology/"
                  target="_blank"
                >
                  Technology
                </a>
              </li>
              <li>
                <a
                  href=" https://www.unthinkable.co/analytics/"
                  target="_blank"
                >
                  {" "}
                  Analytics
                </a>
              </li>
              <li>
                <a href=" https://www.unthinkable.co/security/" target="_blank">
                  {" "}
                  Security
                </a>
              </li>
              <li>
                <a
                  href="https://www.unthinkable.co/intelligence/"
                  target="_blank"
                >
                  {" "}
                  Intelligence
                </a>
              </li>
              <li>
                <a href="https://www.unthinkable.co/cloud-2/" target="_blank">
                  {" "}
                  Cloud
                </a>
              </li>
              <li>
                <a
                  href=" https://www.unthinkable.co/marketing/"
                  target="_blank"
                >
                  Marketing
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 col-md-3 col-sm-2">
            <ul>
              <span>Verticals</span>
              <li>
                <a href="https://www.unthinkable.co/edtech/" target="_blank">
                  Edtech
                </a>
              </li>
              <li>
                <a
                  href="https://www.unthinkable.co/healthcare/"
                  target="_blank"
                >
                  Healthcare
                </a>
              </li>
              <li>
                <a
                  href=" https://www.unthinkable.co/digital-commerce/"
                  target="_blank"
                >
                  Digital Commerce
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 col-md-3 col-sm-2">
            <ul>
              <span>Contact Us</span>
              <li>
                <a href="https://www.unthinkable.co/career/" target="_blank">
                  {" "}
                  Career
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        {screenWidth <= MOBILE_WIDTH && <FooterIcons />}
        <div className="row footer">
          <div className="col-6 left">
            <a
              href=" https://www.unthinkable.co/privacy-policy/"
              target="_blank"
            ></a>{" "}
            Privacy Policy
          </div>
          <div className="col-6 right">
            {" "}
            © 2023
            <span className="text-style-1"> Unthinkable </span>| All Rights
            Reserved
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
