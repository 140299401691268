import { useState, useEffect, useContext, useRef } from 'react';

import { ClothItems, LipstickItems, RingsItems } from '../../../Data/data';
import {
	STATIC_FILE,
	APPAREL_TRY_ON_STATIC_FILE,
} from '../../../Constants/apiEndpoint';

import Loader from '../../Loader/Loader';
import Product from './Product/Product';
import ProductUpload from './ProductUpload/ProductUpload';
import ShadesCard from '../ShadesCard/ShadesCard';
import {
	getClothesListService,
	getRingListService,
} from '../../../Services/cosmeticTryOnService';
import { uploadClothModal } from '../../../Context/VirtualTryOn/virtualTryOnActions';
import { virtualTryOnContext } from '../../../Context/VirtualTryOn/VirtualTryOnProvider';
import styles from './products-style.module.css';
import { SOMETHING_WENT_WRONG_TRY_FIX } from '../../../Constants/errorMessage';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import slider1st from '../../../Assets/img/slider1st.webp';
import slider2nd from '../../../Assets/img/slider2nd.webp';
import './ProductsStyles.css'

const Products = ({isVisible}) => {
	const [state, dispatch] = useContext(virtualTryOnContext);

	const [productList, setProductList] = useState([]);
	const [isLoading, setIsLoading] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const sliderRef = useRef(null);


	const fetchLipstickProduct = () => {
		setProductList([]);
		return LipstickItems;
	};

	const fetchRingProduct = async () => {
		setProductList([]);
		const response = await getRingListService();
		if (response?.error) {
			setIsLoading(false);
			setErrorMsg(SOMETHING_WENT_WRONG_TRY_FIX);
		} else {
			let links = response?.data?.data?.links;
			if (links.length > 3) {
				links = links.slice(0, 3);
			}
			const newLink = links?.map((item, idx) => {
				const file_detail = item?.file_name.split('/');
				return {
					id: idx + 1,
					file_name: file_detail[file_detail.length - 1],
					img: `${STATIC_FILE}${item?.file_name}`,
          br: RingsItems[idx].br,
          p: RingsItems[idx].p,
				};
			});
			return newLink;
		}
	};

	const fetchApparelProduct = async () => {
		setProductList([]);
		const response = await getClothesListService();
		if (response?.error) {
			setIsLoading(false);
			setErrorMsg(SOMETHING_WENT_WRONG_TRY_FIX);
		} else {
			let links = response?.data?.data?.links;
			if (links.length > 2) {
				links = links.slice(0, 2);
			}
			const newLink = links?.map((item, idx) => {
				const file_detail = item?.path.split('/');
				return {
					id: idx + 1,
					file_name: file_detail[file_detail.length - 1],
					img: `${APPAREL_TRY_ON_STATIC_FILE}${item?.path}`,
					br: ClothItems[idx].br,
					p: ClothItems[idx].p,
				};
			});
			return newLink;
		}
	};

	const showUploadModalHandler = () => {
		dispatch(uploadClothModal(true));
	};

	useEffect(() => {
		setErrorMsg('');
		(async () => {
			setIsLoading(true);
			let product = [];
			setProductList([]);
			if (
				state?.selectedProduct?.category === 'makeup' &&
				state?.selectedProduct?.subCategory === 'lipstick'
			) {
				product = fetchLipstickProduct();
			} else if (
				state?.selectedProduct?.category === 'jewelry' &&
				state?.selectedProduct?.subCategory === 'rings'
			) {
				product = await fetchRingProduct();
			} else if (
				state?.selectedProduct?.category === 'apparel' &&
				state?.selectedProduct?.subCategory === 'clothes'
			) {
				product = await fetchApparelProduct();
			}
			setProductList(product);
			setIsLoading(false);
		})();
	}, [state?.selectedProduct?.subCategory]);

	return (
		<div className={styles.container}>
			{errorMsg ? (
				<div className={styles.errorMsg}>{errorMsg}</div>
			) : (
				<>
					{/* <div className={styles.heading}>
						{state?.selectedProduct?.subCategory}
					</div> */}

					<div className={styles.productsWrapper}>
					{/* <Slider
							ref={sliderRef}
							slidesToShow={2}
							slidesToScroll={1}
							autoplay={false}
							autoplaySpeed={2000}
							infinite={false}
							dots={false}
							draggable={true}
							responsive={[
								{
									breakpoint: 990,
									settings: {
										slidesToShow: 2,
										slidesToScroll: 1,
									},
								},
								{
									breakpoint: 770,
									settings: {
										slidesToShow: 1,
										slidesToScroll: 1,
									},
								},
							]}
							prevArrow={
								<img
									className='arrow-img'
									onClick={() => sliderRef?.current?.slickPrev()}
									src={slider1st}
									alt=''
								/>
							}
							nextArrow={
								<img
									className='arrow-img'
									onClick={() => sliderRef?.current?.slickNext()}
									src={slider2nd}
									alt=''
								/>
							}
						> */}

						{productList?.map((item) => {
							return (
								<Product
									key={item?.id}
									id={item?.id}
									img={item?.img}
									fileName={item?.file_name}
									label={item?.br}
									description={item?.p}
								/>
							);
						})}
					
						{productList?.length > 0 &&
							state?.selectedProduct?.category === 'apparel' && (
								<ProductUpload
									iconText='Add Cloth'
									label='Upload a cloth to'
					                subLabel='try on'
									uploadModal={showUploadModalHandler}
									imgFile={state?.clothImage?.file}
									id={state?.clothImage?.id}
									imgLabel='Custom Cloth'
								/>
							)}

						{isLoading && <Loader type='secondary' />}
						{/* </Slider> */}
						
					</div>

					{state?.selectedProduct?.productId &&
						state?.selectedProduct?.subCategory === 'lipstick' && (
							<ShadesCard isVisible={isVisible}/>
						)}
				</>
			)}
		</div>
	);
};

export default Products;
