import { axiosInstance } from "../API/axiosInstance";
import {
  RECOMMENDATION_ENGINE_PRODUCTS_LIST_API,
  RECOMMENDATION_ENGINE_PRODUCT_DESC_API,
  RECOMMENDATION_ENGINE_ITEM_LIST_API,
} from "../Constants/apiEndpoint";

export const recommendationProductList = async () => {
  const response = { error: "", data: null };
  try {
    response.data = await axiosInstance.get(
      RECOMMENDATION_ENGINE_PRODUCTS_LIST_API
    );
  } catch (err) {
    response.error = err?.response;
  }
  return response;
};

export const recommendationProductDescription = async (id) => {
  const response = { error: "", data: null };
  try {
    response.data = await axiosInstance.get(
      `${RECOMMENDATION_ENGINE_PRODUCT_DESC_API}/${id}`
    );
  } catch (err) {
    response.error = err?.response;
  }
  return response;
};

export const recommendationItemList = async (id, basis) => {
  const response = { error: "", data: null };
  try {
    response.data = await axiosInstance.get(
      `${RECOMMENDATION_ENGINE_ITEM_LIST_API}?article_id=${id}&basis=${basis}`
    );
  } catch (err) {
    response.error = err?.response;
  }
  return response;
};
