import { useState } from "react";
import { toast } from "react-toastify";

// import VIDEO_TRANSCRIPTION_INTRO_IMG from '../../Assets/img/video-transcription-intro.webp';
import VIDEO_TRANSCRIPTION_INTRO_IMG from "../../Assets/img/video-transcription-banner.webp";
import CustomButton from "../Widgets/CustomButton/CustomButton";
import Loader from "../Loader/Loader";
import UPLOAD_ICON from "../../Assets/img/Upload Icon-2.svg";
import CHOOSE_ICON from "../../Assets/img/Choose Icon.svg";
import styles from "./video-transcription-intro-style.module.css";
import { TAB_WIDTH, VIDEO_FILE_SIZE } from "../../Constants/constants";
import { uploadFileService } from "../../Services/videoTranscriptionService";
import {
  FILE_TOO_LARGE,
  FILE_UPLOAD_ERR,
  VIDEO_DURATION_TOO_LARGE,
} from "../../Constants/errorMessage";
import useScreenWidth from "../../Hooks/useScreenWidth";

const VideoTranscriptionIntro = (props) => {
  const { setShowSampleVideo, videoSelectHandler } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth] = useScreenWidth();

  const showTemplateVideoHandler = () => {
    setShowSampleVideo(true);
  };

  const uploadVideoHandler = (file) => {
    if (file.size >= VIDEO_FILE_SIZE) {
      toast.error(FILE_TOO_LARGE);
    } else {
      let video = document.createElement("video");
      var fileURL = URL.createObjectURL(file);
      video.src = fileURL;
      video.ondurationchange = async () => {
        console.log(video?.duration);
        if (video?.duration >= 61) {
          toast.error(VIDEO_DURATION_TOO_LARGE);
        } else {
          setIsLoading(true);
          const response = await uploadFileService(file);
          if (response?.data) {
            videoSelectHandler({
              id: 1,
              file_name: response?.data?.data?.file_name,
              file_path: response?.data?.data?.file_path,
              link: fileURL,
            });
          } else if (response?.error) {
            toast.error(FILE_UPLOAD_ERR);
          }
          setIsLoading(false);
        }
      };
    }
  };

  return (
    <div>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader loaderText="Uploading" />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div
            className={`col-lg-6 col-md-6 col-sm-12 ${styles.textContainer}`}
          >
            <div className={styles.videoTextImage}>
              <h4 className={styles.heading}>Video Transcription</h4>
              <p className={styles.subtext}>
                Automatically transcribe your meetings, interviews, lectures,
                and other conversations
              </p>
              {screenWidth <= TAB_WIDTH && (
                <div
                  className={`col-lg-6 col-md-12 col-sm-12 ${styles.videoTranscription}`}
                >
                  <img
                    className={styles.introImg}
                    src={VIDEO_TRANSCRIPTION_INTRO_IMG}
                    alt=""
                  />
                </div>
              )}
            </div>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                Upload video less than 60 seconds
              </li>
              <li className={styles.listItem}>
                Video Format should be either Mp4 or MKV
              </li>
              <li className={styles.listItem}>
                Size of video should be less than 20Mb
              </li>
              <li className={styles.listItem}>
                Best suited for US and UK based accent
              </li>
            </ul>
            <div className={styles.btnWrapper}>
              {/* <div className='upload-btn'> */}
              <CustomButton
                text="Upload Video"
                customStyles={`${styles.btn} ${styles.uploadBtn}`}
                startIcon={UPLOAD_ICON}
                fileUpload
                accept="video/mp4,video/mkv"
                clickHandler={uploadVideoHandler}
              />
              {/* </div> */}
              {/* <div className='choose-btn'> */}
              <CustomButton
                text="Choose from template"
                customStyles={`${styles.btn} ${styles.templateBtn}`}
                startIcon={CHOOSE_ICON}
                clickHandler={showTemplateVideoHandler}
              />
              {/* </div> */}
            </div>
          </div>
          {screenWidth > TAB_WIDTH && (
            <div className="col-lg-6 col-md-6 col-sm-12 videoTranscription">
              <img
                className={styles.introImg}
                src={VIDEO_TRANSCRIPTION_INTRO_IMG}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoTranscriptionIntro;
