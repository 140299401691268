import React from "react";
import ARROW_ICON from "../../Assets/img/arrow.svg";
import styles from "./how_it_works-style.module.css";

const HowItWorks = (props) => {
  const { heading, subHeading, list, displayImg } = props;

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.subHeading}>{subHeading}</div>
        <div className={styles.mainWrapper}>
          <div className={styles.contentWrapper}>
            {list?.map((data, idx) => {
              return (
                <div key={idx} className={styles.content}>
                  <img className={styles.arrowIcon} src={ARROW_ICON} alt="" />
                  <p className={styles.text}>{data}</p>
                </div>
              );
            })}
          </div>
          <img className={styles.img} src={displayImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
