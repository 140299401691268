import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { recommendationProductList } from "../../../Services/recommendationEngineService.js";
import ProductsList from "../ProductsList/ProductsList";
import { styles } from "./ProductsListContainerStyles.js";

export default function ProductsListContainer() {
  const [products, setProducts] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const response = await recommendationProductList();
      if (response?.data?.status === 200) {
        setProducts(response?.data?.data?.data);
        setIsLoading(false);
      } else {
        setIsError(true);
      }
    })();
  }, []);

  return (
    <Box sx={styles.productListContainer}>
      <ProductsList
        title="Clothing"
        products={products}
        isError={isError}
        titleStyle={styles.headingText}
        rowGap={10}
        isLoading={isLoading}
      />
    </Box>
  );
}
