export const GET_IN_TOUCH_LINK =
	'https://insights.daffodilsw.com/landing/free-consultation';
export const DAFFODILSW_MAIN_LINK = 'https://www.daffodilsw.com/';
export const VIRTUAL_TRY_ON_APP_LINK =
	'https://play.google.com/store/apps/details?id=com.daffodilsw.virtual_try_on&hl=en_IN&gl=US';
export const DAFFODILSW_ABOUT_US_LINK = 'https://www.daffodilsw.com/about-us/';
export const DAFFODILSW_LOCATIONS_LINK =
	'https://www.daffodilsw.com/locations/';
export const FACEBOOK_LINK = 'https://www.facebook.com/daffodilsw';
export const TWITTER_LINK = 'https://twitter.com/daffodilsw';
export const YOUTUBE_LINK =
	'https://www.youtube.com/channel/UC_FIqVM3B0RJLY1HtpAdRPw/videos';
export const LINKDEIN_LINK =
	'https://www.linkedin.com/company/daffodil-software-ltd-';
