import keyboard from "../../../Assets/img/keyboard.svg";
// import microphone from '../../../Assets/img/microphone.svg';
import styles from "./speech-style.module.css";
import microphone from "../../../Assets/img/microphone-green.svg";
export default function Speech(props) {
  const { setSpeech, handleClick, isMicrophoneActive } = props;
  return (
    <div className={styles.container}>
      <div onClick={(prev) => setSpeech(!prev)}>
        <img className={styles.icon} src={keyboard} alt="" />
      </div>

      <div className={styles.microphoneIcon}>
        <div
          className={isMicrophoneActive ? styles.pulse : ""}
          onClick={handleClick}
        >
          <img className={styles.icon} src={microphone} alt="" />
        </div>
      </div>
    </div>
  );
}
