import { useState } from 'react';

// import smallMicrophone from '../../../Assets/img/small-microphone.webp';
import smallMicrophone from '../../../Assets/img/small-Microphone-green.svg';
import sendButton from '../../../Assets/img/Send-green.svg';
// import sendButton from '../../../Assets/img/send-button.webp';
import styles from './input_box_chat-style.module.css';

export default function InputBoxChat({ setSpeech, setFormValue }) {
	const [inputValue, setInput] = useState('');

	const handleInput = (e) => {
		setInput(e.target.value);
	};

	const enterButton = () => {
		if (inputValue) {
			setFormValue(inputValue);
		}
		setInput('');
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			enterButton();
		}
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.inputWrapper}>
					<input
						className={styles.textInput}
						type='text'
						value={inputValue}
						onChange={(e) => handleInput(e)}
						placeholder='Type your message here'
						onKeyDown={handleKeyPress}
					/>
				</div>
				<div className={styles.btnWrapper}>
					<div onClick={() => setSpeech(true)}>
						<img
							className={styles.img}
							src={smallMicrophone}
							alt=''
						/>
					</div>

					<div onClick={() => enterButton()}>
						<img
							className={styles.img}
							src={sendButton}
							alt=''
						/>
					</div>
				</div>
			</div>
		</>
	);
}
