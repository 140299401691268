const imageDownload = async (props) => {
	const { imgUrl } = props;
	const image = await fetch(imgUrl);

	//Split image name
	const nameSplit = imgUrl.split('/');
	const duplicateName = nameSplit.pop();

	const imageBlog = await image.blob();
	const imageURL = URL.createObjectURL(imageBlog);
	const link = document.createElement('a');
	link.href = imageURL;
	link.download = '' + duplicateName + '';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default imageDownload;
